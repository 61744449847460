<div class="row">
  <div class="col s12 m10 l10">

      <!-- Start of first wrapper -->

      <div class="first-wrapper section scrollspy" id="principles">
          <p class="first-wrapper-title">USSD-Store</p>
          <br>
          <!-- Start of Principles section -->
          <p class="first-wrapper-title">1. Principles</p>
          <p>
            Bizao offers the opportunity to integrate your service into the Orange USSD-Store
which enables a simple and straight-forward way to provide a USSD service to
Orange subscribers.
            </p>


            <p>
              As reminder, a USSD service is a standard web application that delivers
              HTML pages.
          </p>

          <p>
            It is based on a subset of xHTML: Orange back-end server sends HTTP GET requests
to the SP using SSL, on behalf of the mobile user, exactly like a web browser does.
The SP is expected to reply with xHTML pages
          </p>

          <p>
            Here, no token is required. This is because within the USSD case, the HTTP client is on
Orange side and the HTTP server is your application.
          </p>

          <p>
            After your complete integration, the end-user will have access to your service through
USSD short-codes and will be able to navigate into it without using data.
          </p>

          <p>
            Please note that for the billing and the notification step, the SP will have to manage it
from its side using Bizao’s APIs (Payment API - DCB and Notification API).
          </p>
          <!-- End of Principles section -->

          <!-- Start of List of 3G-redirection URL by country -->
          <p class="first-wrapper-title">2. Description</p>


          <p>To subscribe and integrate your services in Orange USSD-Store, you need to
            provide us: </p>


          <p style="margin-left: 5%;">

            a. The Country-name: your targeted country
          </p>

          <p style="margin-left: 5%;">

            b. Service-name: your service name as it will appear in the store
          </p>

          <p style="margin-left: 5%;">

           c. Service-category: the category of your service
          </p>

          <p style="margin-left: 5%;">

            d. Service-UR: the URL of your service
           </p>

           <p style="margin-left: 10%;">a. The URL should contain <b>“/buy/”</b> in the path. </p>

           <p style="margin-left: 10%;">b. The URL should be in “https”: the self-signed certificate are not accepted </p>

           <br> <br>
<p>
  Below an example of access to the main menu of your application:
</p>

<br>


<pre>
  <div class="code">
    GET https://www.aspserver.com/ussd/ HTTP/1. 1
    Accept: text/html
    User-MSISDN: acr:USDSHP-200-
    D6XkRcAC8oWQ0qlb3Yg0bfcdcvLyt9cJCjOC7+ZA4UU = User-TOKEN:
    B64lWAJNyo68cpEj1go/anZfvvy6nYv6vqL+/9E1eZ9Idkht8tV9UNbYdJgIoWHee1DJX1
    9yWqMCZaWkw/WbR/03A==|MCO=OSN|tcd=1464145112|ted=1464145212|1XL1
    wxPglrErhUHhHUpYhLCEFvcd=User-Language: fr
    User-SessionId: 430d4b0d8301648f3909e363a42dfb0f
  </div>
  </pre>

  <p>Your answer </p>

  <pre>
<div class="code">
    &#60;div class="code"&#62;
      HTTP/1.1 200 OK
      Content-Type: text/html; charset=UTF-8
      &#60;?xml version="1.0" encoding="UTF-8"?&#62;
      &#60; html&#62;
      &#60;body&#62; Welcome to my service&#60;br/&#62; Please select:&#60;br/&#62;
      &#60;a href="menuOne.html"&#62;Activate&#60;/a&#62;&#60;br/&#62; &#60;a
      href="misc/menuTwo.html"&#62;Options&#60;/a&#62;&#60;br/&#62; &#60;a
      href="help.jsp?option=2"
      accesskey="9"&#62;Contact&#60;/a&#62; &#60;/body&#62;
      &#60;/html&#62;

    </div>
    </pre>
    <br>




    <p>
      Please note:
    </p>
    <p style="margin-left: 5%;">
• The title tag is not accepted <br>
• Do not use numbers menu like:
    </p>

    <pre>
      <div class="code">

        USSD app menu:&#60;br/&#62;
        1-Test&#60;br/&#62;
        2-B&#60;br/&#62;3-D&#60;br/&#62;4-MMMM&#60;br/&#62;5- GGGG&#60;br/&#62;...&#60;br/&#62;
        9-OTHER&#60;form action="orange_ussd.php"&#62;

          </div>
          </pre>

<br>

          <p>
            • Only use href syntax for the menu like one of these samples:
          </p>

          <pre>
            <div class="code">


              &#60;a href="menuOne.html"&#62;Activate&#60;/a&#62;&#60;br/&#62;
              &#60;a href="misc/menuTwo.html"&#62;Options&#60;/a&#62;&#60;br/&#62;
              &#60;a href="help.jsp?option=2" accesskey="9">Contact&#60;/a&#62;

                </div>
                </pre>

                <p>
                  The local USSD gateway may add links for navigation commands, like
                  <br>
                  0:Back, <br>
                  00: Home <br>
                  This is a country dependant feature and cannot be changed through the store.

                </p>

                <p>
                  Below an example of input requirement in your application:
                </p>

                <p>
                  The request :
                </p>
<br>
<pre>
  <div class="code">


    GET https://www.aspserver.com/ussd/askForZipcode.html HTTP/1.1 Accept:
text/html User-MSISDN: acr:USDSHP-200-
D6XkRcAC8oWQ0qlb3Yg0bfcdcvLyt9cJCjOC7+ZA4UU= User-Language: fr UserSessionId: 430d4b0d8301648f3909e363a42dfb0f

      </div>
      </pre>

      <br>

      <p>
        <b>Your answer</b>
      </p>
<br>
<pre>
  <div   class="code">


    HTTP/1.1 200 OK Content-Type: text/html; charset=UTF-8
    &#60;?xml version="1.0" encoding="UTF-8"?&#62; &#60;html&#62; &#60;body&#62;
    Please enter your zip code:&#60;br/&#62;
    &#60;form action="enter_zip.jsp"&#62;
    &#60;input type="text" name="response"/&#62;


    &#62;/form&#62;
    &#62;/body&#62;
    &#62;/html&#62;

  </div>
      </pre>


      <p>
        Once the user has dialed its response, Orange back-end APIs calls back the ASP on
the URL indicated in the form:
      </p>

      <p>
        GET https://www.aspserver.com/ussd/enter_zip.jsp?response=38400
      </p>

      <p>
        Please note:  <br>
      </p>

      <p>
        • The &#60;input&#62; tag is fixed and must contains type="text" name="response" <br>
        • The user answer is always carried using the "response" parameter. If
        navigation commands (0:Back, 00:Home) are provided by the local USSD
        browser, then navigation keys are evaluated first : in the above example (into
        the frame), 0 or 00 are understood as navigation shortcuts, and not replies to
        the question; so, “form action” works only if there is only one data input, and
        not a list of choices.
      </p>

      <br>
      <p>
      <b>Below an example of session closing in your application:</b>
      </p>

      <p>
        When the user sequence is finished, with no possibility to reach another page, then
the session can be ended by the ASP using the <meta> tag and the specific
name/value pair: "nav / end"
      </p>

      <p>
        The request :
      </p>

      <pre>
      <div class="code">


        GET https://www.aspserver.com/ussd/byeMessage.html HTTP/1.1 Accept:
text/html User-MSISDN: acr:USDSHP-200-
D6XkRcAC8oWQ0qlb3Yg0bfcdcvLyt9cJCjOC7+ZA4UU= User-Language: fr
User-SessionId: 430d4b0d8301648f3909e363a42dfb0f

      </div>
    </pre>


      <p> Your answer</p>


      <pre>
      <div class="code">


        HTTP/1.1 200 OK
        Content -Type: text/html; charset=UTF-8
        &#60;?xml version="1.0" encoding="UTF-8"?&#62; &#60;html&#62; &#60;head&#62;
        &#60;meta name="nav" content="end"/&#62;
        &#60; /head&#62;
        &#60; body&#62; Thanks for using my Service&#60;br/&#62; Goodbye !
        &#60;/body&#62;
        &#60; /html&#62;

      </div>
    </pre>
          <p>
            Sample notification: in the notification below, evenType is <b>« orderDeletion »</b>
          </p>

          <pre>
            <div class="code">
      &#123;
      "note":&#123;
      "text":"partner data"
      &#125;,
      "event":&#123;
      "id":"60585db6f59e4e66ac506a97",
      "relatedParty":[
      &#123;
      "id":"PDKSUB-200-UNcG4m15CednnxdFqXJNfX5CfDh\/BcEqqhNREfyKh+A=",
      "name":"ISE2",
      "role":"subscriber",
      "aliases":null,
      "individual":null
      &#125;,&#123;
      "id":"BIZAO",
      "name":"BIZAO",
      "role":"partner",
      "aliases":null,
      "individual":null
      &#125;,&#123;
      "id":"Funcell",
      "name":"Funcell",
      "role":"retailer",
      "aliases":null,
      "individual":null
      &#125;
      ],
     "order":&#123;
     "id":"60585db6f59e4e66ac506a94",
     "creationDate":"2021-03-22T09:04:54.189Z",
     "state":"Completed",
     "orderItem":&#123;
     "chargedAmount":80,
     "currency":"XAF",
     "validityDate":"2021-03-23T09:04:54.111Z",
     "nextCharge":"2021-03-23T09:04:54.111Z",
     "product":&#123;
     "id":"Funcell daily CM",
     "href":"na",
     "productCharacteristic":[
     &#123;
     "name":"periodicity",
     "value":"86400"
     &#125;,
     &#123;
     "name":"startDateTime",
     "value":"2021-03-22T09:04:54.111Z[UTC]"
     &#125;,
     &#123;
     "name":"endDateTime",
     "value":"2021-03-23T09:04:54.111Z[UTC]"
     &#125;
     ] &#125;
     &#125;
     &#125;
     &#125;,
     "eventTime":"2021-03-22T09:04:57.402Z",
     "eventType":"orderDeletion",
     "country":"xx",
     "provider":"digipay",
     "at_received":"2021-03-22 09:04:57"
    &#125;
            </div>
            </pre>

            <img src="../../../assets/images/orange store.png" alt="">

<br>

<p class="first-wrapper-title">3.  USSD-Short-code available by country </p>


<p>
  The end-user will start USSD navigation by using one of the USSD Short-Code below:
</p>

<br>

<table>
  <tr>
    <th>Country</th>
    <th>Ussd-code</th>
  </tr>

  <tr>
    <td>Ivory-Coast</td>
    <td>#303*10# </td>
  </tr>
  <tr>
    <td>RDCongo</td>
    <td>#303*10# </td>
  </tr>
  <tr>
    <td>Cameroon</td>
    <td>#203*10#  </td>
  </tr>
  <tr>
    <td>Senegal</td>
    <td>#303*10# </td>
  </tr>
  <tr>
    <td>Burkina Faso</td>
    <td>#303*10# </td>
  </tr>

</table>


</div>
