import { Component, OnInit } from '@angular/core';

declare var jQuery: any;
@Component({
  selector: 'app-mnt-optauthentication',
  templateUrl: './mnt-optauthentication.component.html',
  styleUrls: ['./mnt-optauthentication.component.css']
})
export class MntOPTAuthenticationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {

    (function ($) {

      $(document).ready(function(){
       $('.scrollspy').scrollSpy();
       $('.row .table-of-contents').pushpin({ top: 80 });
     });
   })(jQuery);

  }

}
