<div class="container" id="section_wrapper">
    <nav class="main_nav main_nav_before_scroll_line main_nav_before_scroll_shadow" id="main_nav">
        <div class="nav-wrapper white input-field">
            <a href="#" class="brand-logo img-responsive" id="brand-logo"><img src="../../../assets/images/logo-png.png" alt=""
                    height="auto" width="150"></a>
            <a href="#" class="sidenav-trigger" data-target="mobile-links">
                <i class="material-icons black-text">menu</i>
            </a>
            <ul id="nav_desktop" class="right hide-on-med-and-down">
                <li><a class="black-text active" routerLink="/home" routerLinkActive="is-active"> <img
                            src="../../../assets/images/home.svg" alt="" class="align-position"> Accueil</a></li>
                <li><a routerLink="/api-testor" routerLinkActive="is-active" class="black-text"> <img
                            src="../../../assets/images/api_testor.svg" alt="" class="align-position"> API Testor</a>
                </li>
                <li><a class="black-text modal-trigger" href="#downloads" routerLinkActive="is-active"><img
                            src="../../../assets/images/download.svg" alt="" class="align-position"> Download</a></li>
            </ul>
        </div>
    </nav>
    <br>
    <!-- <hr id="main_nav_hr"> -->
    <ul class="sidenav collapsible" data-collpasible="accordion" id="mobile-links">
        <li><a class="black-text active mobile_welcome_item sidenav-close" routerLink='/'> <img
                    src="../../../assets/images/home.svg" alt="" class="align-position"> <b>Accueil</b></a></li>
        <li class="mobile_items">
            <div class="collapsible-header"><img src="../../../assets/images/gettingStarted.svg" alt=""
                    class="align-position"><b>Getting Started</b> </div>
            <div class="collapsible-body">
        <li class="direct_items"><a routerLink="/get_access_token" routerLinkActive="is-active"
                class="secondary-titles  sidenav-close">Generate
                access_token</a></li>
        <li class="direct_items"><a routerLink="/sandbox" routerLinkActive="is-active" class="secondary-titles sidenav-close">Create Sandbox
                Account</a>
        </li>
</div>
</li>
<li class="mobile_items">
    <div class="collapsible-header"><img src="../../../assets/images/ourServices.svg" alt=""
            class="align-position"><b>Our offers</b> </div>
    <div class="collapsible-body">
<li class="direct_items">
    <a class=" modal-trigger secondary-titles" href="#dcb-digest">Direct Carrier Billing</a>
</li>
<li class="direct_items">
    <a class="modal-trigger secondary-titles" href="#mm-digest">
        Mobile Money</a>
</li>
<li class="direct_items">
    <a class="modal-trigger secondary-titles" href="#mt-digest">Money transfer</a>
</li>
</div>
</li>

<!--Start of API BookStore  -->

<li>
    <div class="collapsible-header">
        <img src="../../../assets/images/apiBookstore.svg" alt="" class="align-position"><b>API BookStore</b>
    </div>
    <div class="collapsible-body">
        <div class="row">
            <div class="col s12 m12">
                <ul class="collapsible" data-collapsible="accordion">
                    <!-- Start of DCB Orange -->
                    <li>
                        <div class="collapsible-header">
                            <i class="material-icons">chevron_right</i>DCB
                        </div>
                        <div class="collapsible-body">
                            <div class="row">
                                <div class="col s12 m12">
                                    <ul class="collapsible" data-collapsible="accordion">
                                        <!-- Start of DCB Orange -->
                                        <li>
                                            <div class="collapsible-header submenu"><i class="material-icons">chevron_right</i>Orange</div>
                                            <div class="collapsible-body">
                                                <!-- Start Orange DCB Items -->
                                                <div class="row">
                                                    <div class="col s12 m12">
                                                        <ul class="collapsible" data-collapsible="accordion">
                                                            <li>
                                                                <div class="collapsible-header submenu_1"><i class="material-icons">chevron_right</i>Authentication</div>
                                                                <div class="collapsible-body">
                                                                    <div class="row">
                                                                        <div class="col s12 m12">
                                                                            <ul class="collapsible"
                                                                                data-collapsible="accordion">
                                                                                <li>
                                                                                    <div class="collapsible-header submenu_2">
                                                                                        <i class="material-icons">chevron_right</i>Authentication methods</div>
                                                                                    <div class="collapsible-body">
                                                                                <li class="submenu_2"><a class="sidenav-close" routerLink="/otp_authentication"
                                                                                        routerLinkActive="is-active">OTP
                                                                                       </a></li>
                                                                                <li class="submenu_2"><a class="sidenav-close" routerLink="/orange-he-auth"
                                                                                        routerLinkActive="is-active">3G/4G
                                                                                       </a></li>
                                                                                <li class="submenu_2"><a class="sidenav-close" routerLink="/mo-user-authentication"
                                                                                        routerLinkActive="is-active">MO
                                                                                       </a></li>
                                                                        </div>
                                                            </li>
                                                            <li>
                                                                <div class="collapsible-header submenu_1"> <i class="material-icons">chevron_right</i>Payment</div>
                                                                <div class="collapsible-body">
                                                            <li class="submenu_2"><a class="sidenav-close" routerLink="/orange-dcb-payment-api"
                                                                    routerLinkActive="is-active">Payment API</a></li>
                                                    </div>
                                        </li>
                                        <li>
                                            <div class="collapsible-header submenu_1"> <i class="material-icons">chevron_right</i>Notifications</div>
                                            <div class="collapsible-body">
                                                <li class="submenu_3"><a class="sidenav-close" routerLink="/orange-dcb-notification-api" routerLinkActive="is-active">SMS MT
                                                    API</a></li>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="collapsible-header submenu_1"> <i class="material-icons">chevron_right</i>Unsubscription</div>
                        <div class="collapsible-body">
                            <li class="submenu_3"><a class="sidenav-close" routerLink="/orange-unsubscription-api" routerLinkActive="is-active">Unsubscription
                                API</a></li>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <!-- End Orange DCB Items -->
    </div>
</li>
<!-- End of DCB Orange -->
<!-- Start of MTN DCB -->
<li>
    <div class="collapsible-header submenu"><i class="material-icons">chevron_right</i>MTN</div>
    <div class="collapsible-body">
        <!-- Start Orange DCB Items -->
        <div class="row">
            <div class="col s12 m12">
                <ul class="collapsible" data-collapsible="accordion">
                    <li>
                        <div class="collapsible-header submenu_1"><i class="material-icons">chevron_right</i>Subscription</div>
                        <div class="collapsible-body">
                            <div class="row">
                                <div class="col s12 m12">
                                    <ul class="collapsible"
                                        data-collapsible="accordion">
                                        <li>
                                            <div class="collapsible-header submenu_2">
                                                <i class="material-icons">chevron_right</i>Authentication methods</div>
                                            <div class="collapsible-body">
                                        <li class="submenu_3"> <a class="sidenav-close" routerLink="/mtn-he-authentication" routerLinkActive="is-active">Implicit mode
                                            &#x28;HE&#x29;</a></li>

                                </div>
                    </li>
<li>
    <div class="collapsible-header submenu_2"> <i class="material-icons">chevron_right</i>Notifications</div>
    <div class="collapsible-body">
        <li class="submenu_3"><a class="sidenav-close" routerLink="/mtn-subscription-notif-api" routerLinkActive="is-active">Notification API</a></li>
    </div>
</li>
</ul>
</div>
</div>
</div>
</li>

<li>
    <div class="collapsible-header submenu_1"><i class="material-icons">chevron_right</i>Unsubscription</div>
    <div class="collapsible-body">
        <div class="row">
            <div class="col s12 m12">
                <ul class="collapsible"
                    data-collapsible="accordion">
                    <li>
                        <div class="collapsible-header submenu_2">
                            <i class="material-icons">chevron_right</i>Unsubscription API</div>
                        <div class="collapsible-body">
                    <li class="submenu_1"> <a class="sidenav-close" routerLink="/mtn-unsubscription-api" routerLinkActive="is-active">Unsubscription APIS</a></li>
                    <li class="submenu_1"> <a class="sidenav-close" routerLink="/orange-ussd-store" routerLinkActive="is-active">USSD Store</a></li>

            </div>
</li>
<li>
<div class="collapsible-header submenu_2"> <i class="material-icons">chevron_right</i>Notifications</div>
<div class="collapsible-body">
<li class="submenu_3"><a class="sidenav-close" routerLink="/mtn-unsubscription-notif-api" routerLinkActive="is-active">Notification API</a></li>
</div>
</li>
</ul>
</div>
</div>
</div>
</li>

</ul>
</div>
</div>
<!-- End Orange DCB Items -->
</div>
</li>
<!-- End of MTN DCB -->
</ul>
</div>
</div>
</div>
</li>
<!-- End of DCB Orange -->



<li>
  <div class="collapsible-header">
      <i class="material-icons">chevron_right</i>Merchant Payment
  </div>
  <div class="collapsible-body">




    <ul class="collapsible" data-collapsible="accordion">
      <li>
          <div class="collapsible-header submenu_1"><i class="material-icons">chevron_right</i>Mobile Money</div>
          <div class="collapsible-body">
            <ul>

              <li class="submenu_1"><a class="sidenav-close" routerLink="/mobile-money-payment-api" routerLinkActive="is-active">Payment API</a></li>
              <li class="submenu_1"><a class="sidenav-close" routerLink="/get-status-api" routerLinkActive="is-active">GetStatus API</a></li>
             </ul>
            </div>
            </li>

            <li>
              <div class="collapsible-header submenu_1"><i class="material-icons">chevron_right</i>VISA MASTERCARD</div>
              <div class="collapsible-body">
                <ul>

                  <li class="submenu_1"><a class="sidenav-close" routerLink="/visa-mastercard" routerLinkActive="is-active">DebitCards API</a></li>
                  <li class="submenu_1"><a class="sidenav-close" routerLink="/visa-mastercard-get-status" routerLinkActive="is-active">GetStatus API</a></li>
                 </ul>
                </div>
                </li>
            </ul>





  </div>
</li>

<li>
  <div class="collapsible-header">
      <i class="material-icons">chevron_right</i>MoneyTransfer
  </div>
  <div class="collapsible-body">
     <ul>
      <li class="submenu_1"><a class="sidenav-close" routerLink="/moneytransfer-api" routerLinkActive="is-active">MoneyTransfer API</a></li>
      <li class="submenu_1"><a class="sidenav-close" routerLink="/moneytransfer-getstatusapi" routerLinkActive="is-active">GetStatus API</a></li>
     </ul>
  </div>
</li>



<li>
  <div class="collapsible-header">
      <i class="material-icons">chevron_right</i>Bulk Payment
  </div>
  <div class="collapsible-body">
     <ul>
      <li class="submenu_1"><a class="sidenav-close" routerLink="/bulk-api" routerLinkActive="is-active">Bulk API</a></li>
      <li class="submenu_1"><a class="sidenav-close" routerLink="/bulk-get-status-api" routerLinkActive="is-active">GetStatus API</a></li>
     </ul>
  </div>
</li>
</ul>
</div>
</div>
</div>
</li>

<li class="mobile_items"><a class="black-text sidenav-close" routerLink="/api-testor"> <img
            src="../../../assets/images/api_testor.svg" alt="" class="align-position"><b>API Testor</b></a></li>
<li class="mobile_items"><a class="black-text sidenav-close" href="#"><img src="../../../assets/images/download.svg"
            alt="" class="align-position">
        <b>Download</b></a>
</li>
</ul>
</div>

<!-- Modal Structure for dcb-->
<div id="dcb-digest" class="modal">
    <div class="modal-content">
        <h4>Direct Carrier Billing</h4>
        <p>
            Monetizing digital content in Africa can seem like a puzzle because of the very high use of cash in daily
            transactions and the low use of credit cards for online payments. Africa’s mobile penetration rate of over
            80% offers new and unique opportunities for digital content publishers. Bizao’s technical platform allows
            content publishers to access the billing systems of telecom operators in order to bill their services
            directly on the telephone credit. Thus, your digital contents (music, SVOD, games, newspapers, …) are
            accessible to millions of users in several African countries in one click because Bizao has partnerships
            with major telecom operators.
        </p>
    </div>
    <div class="modal-footer learn-more">
        <a href="https://bizao.com/en/carrier-billing-2/" target="_blank" class="modal-close"><u>Learn more about
                DCB</u></a>
    </div>
</div>

<!-- Modal Structure for mobile money-->
<div id="mm-digest" class="modal">
    <div class="modal-content">
        <h4>Mobile Money</h4>
        <p>If you are looking for a robust and secure solution allowing your customers or users to pay by mobile money
            or credit card, Bizao is the ideal partner for you. Our solutions are used by multinationals, SMEs,
            associations and public administration. Thanks to our unique contract, you have access to all the payment
            methods of our banking partners (local and international bank cards) and telecom operators (Orange, MTN,
            Free, …) present in about ten countries in Africa. Whether you have a website or not, a mobile application
            or not, we have an answer adapted to your needs for collecting and transferring funds whatever your digital
            maturity.</p>
    </div>
    <div class="modal-footer learn-more">
        <a href="https://bizao.com/en/merchant-payment/" target="_blank" class="modal-close"><u>Learn more about Mobile
                Money</u></a>
    </div>
</div>


<!-- Modal Structure for money transfer-->
<div id="mt-digest" class="modal">
    <div class="modal-content">
        <h4>Money transfer</h4>
        <p>
            Bizao revolutionizes the customer experience in international money transfer. The funds are directly
            transferred in real time to the recipients’ “Mobile Money” accounts, regardless of their mobile operator and
            throughout the territory, including rural areas.
        </p>
    </div>
    <div class="modal-footer learn-more">
        <a href="https://bizao.com/en/money-transfer/" target="_blank" class="modal-close"><u>Learn more about Money
                transfer</u></a>
    </div>
</div>


<!-- Modal Structure for Downloads-Temproary -->
<div id="downloads" class="modal">
    <div class="modal-content">
        <h4>Downloads</h4>
        <p>
            Download all Bizao API documentation on PDF format.
            <span class="learn-more pdf_download"><a type="button" href="../../../assets/doc_pdf_format/Bizao_API_official_documentation.zip" class="modal-close"><u>Download HERE</u></a>
            </span>
        </p>

    </div>
</div>

