<div class="row">
  <div class="col s12 m10 l10">
      <div class="first-wrapper">
          <p class="first-wrapper-title section scrollspy" id="principles">Subscription API</p>
          <br>

          <p class="first-wrapper-title section">1. Principles</p>

          <p>
            The “subscription” API will manage the dcb subscription user’s traffic, including the renewal
according to each Product/Service periodicity Below the overview of the End2End subscription
flow
          </p>

          <img src="/assets/images/mtnapisubscription.png"  alt=""
          class="ui fluid image">


          <p class="first-wrapper-title section">2.Description</p>

          <p>
            Below the endpoint to access to this API (Bizao will provide you the credential/token to
            access to this API) :

          </p>

          <p style="margin-left: 5%;">

            &#8226;Bizao-URI: subscription/v1 <br>
            &#8226;Bizao-Host: https://api.bizao.com <br>
            &#8226;Method: POST <br>
          </p>

          <p>
           <b> Note:</b> <br>
          </p>
          <p style="margin-left: 5%;">
o This API use only a Headers, No body need for this API <br>
o This API will response with a ‘Successful’ status for each new Subscription query in the
HE flow. It will be ‘Pending’ in the OTP flow <br>

</p>


<p>Below the following <b>Headers</b> required in your query:</p>


<table>
  <tr>
      <td><b>Header</b></td>
      <td><b>Description/Content</b></td>
  </tr>
  <tr>
      <td>Authorization</td>
      <td>YOUR_ACCESS_TOKEN</td>
  </tr>
  <tr>
      <td>mno-name</td>
      <td>(string) the name of operator you target. Example :
        <b>orange, mtn</b>. <br> Bizao will update you regarding the new
        mno set in the hub.</td>
  </tr>
  <tr>
      <td>country-code</td>
      <td>(string) 2 characters code of the country you target
        ( use this norme : <br> ISO 3166 alpha 2 country code, url to
        get the all country-code list : <br>
        <pre><code>https://www.iban.com/country-codes</code></pre> (for example CI is
        the country-code for ivory Coast.)</td>
  </tr>
  <tr>
    <td>product-id</td>
    <td>(alphanum) the Id related to your Product ( will be
      provided by Bizao integration team in service
      Onboarding step )
       <br>
      </td>
</tr>
<tr>
  <td>SP-id</td>
  <td>(alphanum) the Id related to you per country ( will be
    provided by Bizao integration team in service
    Onboarding step )
     <br>
    </td>
</tr>
<tr>
  <td>User-id</td>
  <td>The User MSISDN in format number ( without ‘+’, ‘00’ ) :
    Example :22575338226
    </td>
</tr>
  <tr>
      <td>state</td>
      <td>
        (alphanum) parameter to let put some correlation data
        <b>Note</b> : this parametre must be <b>in EncodedURL format</b>. <br>
        Bizao Will sent you back the value of this parametre
        wihtin the B2B notification
      </td>
  </tr>
  <tr>
    <td>Auth-type</td>
    <td>
      Will contains « HE » or « OTP » ; by default value is « HE »
    </td>
</tr>
</table>

<br>
<p>
  Subscription query sample:
</p>

<div class="sample-wrapper">
  <pre>
<div class="code" >

  POST /subscription/v1 HTTP/1.1
  Host: api.bizao.com
  Authorization: Bearer cb400427-1e56-3we2-b11b-1ff651d7M34e
  country-code: ci
  mno-name: mtn
  sp-id: 2250110010769
  product-id: 22501220000029439
  user-id: 2256691xxxx
  Content-Type: application/x-www-form-urlencoded
  Cache-Control: no-cache
  state: param1%3Dvalue1%26param2%3Dvalue2 // in encoding URL format
  auth-type: zzzzzzz' \// Authentication mode use by endUser
        </div>
        </pre>
        </div>

        <p>
          Successful real time query response sample for HE flow
        </p>

        <div class="sample-wrapper">
          <pre>
        <div class="code" >
&#123;
"subscription-id": "1e851c11-de54-4366-8b6e-6028002edd92", //
"subscription-status": "Successful"
&#125;
                </div>
                </pre>
                </div>

<p> <b>
• subscription-id:</b> the unique id of the subscription handled on Bizao BackEnd <br>
<b>  • subscription-status: </b> the status of your User subscription query

</p>

<p>
  <b>Response sample for OTP flow.</b>
</p>
<div class="sample-wrapper">
  <pre>
<div class="code" >
  &#123;
    "user-id": "23768xxxxxx",
    "subscription-status": "Pending",
    "state": "param1%3Dvalue1%26param2%3Dvalue2kjlasdlkfjsdfsdfasdfasdf",
    "challenge-id": "e0029f04-6ecd-4af5-b7c5-b2c4e89c7e9b"
  &#125;
        </div>
        </pre>
        </div>

<p>
  <b>
• user-id:</b> the user msisdn who subscribe to product. <br>
<b> • subscription-status: </b> the status of your User subscription query <br>
<b> • Challenge-id : </b> id of challenge generated by bizao hub for the OTP. <br>
<b> • State : </b> parametre to let put some correlation data <br>

</p>

<p class="first-wrapper-title">3. Available Country and Operator</p>

<p>
  Below the list of country and MNO where the subscription is available service is available
for Bizao

</p>

<p>
  Within this table you will find the required code for country and operator to use in the
mobile-money query call
</p>
<br>
<br>
<br>

<table>
  <tr>
  <th></th>
  <th>Operator (mno-name)</th>
  <th>Country (country-code)</th>
  <th>currency</th>
</tr>

<tr>
  <td>Ivory Coast</td>
  <td>Mtn</td>
  <td>ci</td>
  <td>XOF</td>
</tr>

<tr>
  <td>Cameroun</td>
  <td>Mtn</td>
  <td>cm</td>
  <td>XAF</td>
</tr>
</table>
<br>
<br>
<br>

<p class="first-wrapper-title">4. DCB-Subscription API error code</p>

<p><b>4.1 Error Format</b></p>

<p>
  In case of error syntax or backend error on the subscription query, Bizao willsent you a
error message in format below:
</p>
<p>
  (http code: 400 in case ofsyntax error & 403 or 500 in case of backEnd error)
</p>
<div class="sample-wrapper">
<pre>
<div class="code" >
   &#123;
    "requestError":  &#123;
    "serviceException":  &#123;
    "messageId": "xxxxxx", // Id of error message
    "text": "xxxx text message xxxx", // Wording of error
    "variables": "xxxxxx text message xxxxxx : list of parameter involved xxx” // List of
    parameter involved in this error
     &#125;
     &#125;
     &#125;
       </div>
       </pre>
       </div>

<p> <b> Below a Sample of error response: </b> </p>

<div class="sample-wrapper">
<pre>
<div class="code" >
   &#123;
    "requestError":  &#123;
    "serviceException":  &#123;
    "messageId": "SVC0004",
    "text": "No valid addresses provided in message part %1",
    "variables": "No valid operator endpoint for request headers. Invalid parameter
    for country-code or mno-name or sp-id or product-id"
     &#125;
     &#125;
     &#125;
       </div>
       </pre>
       </div>

       <p>4.2 Table of API error code</p>

       <table>
        <tr>
          <th>Error code</th>
          <th> Description/Message </th>
          <th>HTTP code</th>
        </tr>

        <tr>
          <td>SVC0001</td>
          <td>text : "A service error occurred. Error code is %1"
            variables: <br>
            no operator - No valid operatorservice for the subscription
            request <br>
            Invalid operator service - No valid operatorservice for the
            subscription status request. <br>
            </td>
          <td>400</td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td>400</td>
        </tr>
        <tr>
          <td>SVC0206</td>
          <td>text: "Mandatory input item missing from request part %1"
            variables: <br>
            invalid sp-id - Missing the header sp-id <br>
            invalid product id - Missing the header product-id <br>
            invalid user id - Missing the header user-id <br>
            invalid state parameter - Missing the header state <br></td>
          <td>400</td>
        </tr>
        <tr>
          <td>SVC0270</td>
          <td>text: API request failed. The subscription/unsubscription was not applied</td>
          <td>500</td>
        </tr>
        <tr>
          <td>any messageid not starting
            with SVC i.e
            00000001</td>
          <td>Please refer BackEnd error codes and list below</td>
          <td>500</td>
        </tr>

       </table>

       <p><b>4.3 Table of Backend error code</b></p>


       <table>
        <tr>
        <th>Error code</th>
        <th>Description</th>
      </tr>

      <tr>
        <td>10000000~10009999</td>
        <td>An internal error occurs in the SDP.</td>

      </tr>


      <tr>
        <td>22007007</td>
        <td>The message ID is incorrect. A message ID must contain 1 to 40 bytes.</td>
      </tr>
      <tr>
        <td>22007008</td>
        <td>The user does not exist.</td>
      </tr>

      <tr>
        <td>22007014</td>
        <td>The user ID is invalid.</td>
      </tr>
      <tr>
        <td>22007001</td>
        <td>Incorrect parameter</td>
      </tr>
      <tr>
        <td>22007034</td>
        <td>The user ID is invalid</td>
      </tr>
      <tr>
        <td>22007038</td>
        <td>The user has no permission to subscribe to this product because the
          product is a gift product.</td>
      </tr>
      <tr>
        <td>22007044</td>
        <td>Notification ofsubscription relationships fails.</td>
      </tr>
      <tr>
        <td>22007053</td>
        <td>Configuration of this user is not found in the SDP system</td>
      </tr>
      <tr>
        <td>22007201</td>
        <td>The product is not allowed to subscribe because of conflict.</td>
      </tr>
      <tr>
        <td>22007203</td>
        <td>The product does not exist.</td>
      </tr>
      <tr>
        <td>22007206</td>
        <td>The product is not in the validity period. The product may have expired
          or have not taken effect.</td>
      </tr>
      <tr>
        <td>22007208</td>
        <td>On-demand products cannot be subscribed to</td>
      </tr>
      <tr>
        <td>22007210</td>
        <td>The user status is abnormal, that is, it is not defined in the system.</td>
      </tr>
      <tr>
        <td>22007211</td>
        <td>The user has been deregistered.</td>
      </tr>
      <tr>
        <td>22007212</td>
        <td>The subscription expiration time is earlier than the current system
          time.</td>
      </tr>
      <tr>
        <td>22007215</td>
        <td>Invalid payment mode.</td>
      </tr>
      <tr>
        <td>22007220</td>
        <td>The notification fails to be sent to the partner.</td>
      </tr>
      <tr>
        <td>22007225</td>
        <td>The user's bonus points is insufficient for the subscription.</td>
      </tr>
      <tr>
        <td>22007227</td>
        <td>The product does not support bonus point payment</td>
      </tr>
      <tr>
        <td>22007230</td>
        <td>The product cannot be subscribed to by a partner.</td>
      </tr>
      <tr>
        <td>22007233</td>
        <td>Waiting for subscriber confirm.</td>
      </tr>
      <tr>
        <td>22007238</td>
        <td>The subscription relationship already exists. The product cannot be
          subscribed to again.</td>
      </tr>
      <tr>
        <td>22007268</td>
        <td>The user's credibility is insufficient and the user cannot subscribe to the
          product.</td>
      </tr>
      <tr>
        <td>22007269</td>
        <td>The user cannot subscribe to the product due to insufficient credit bill
          cycle limit.</td>
      </tr>
      <tr>
        <td>22007270</td>
        <td>The subscription fails because the promotion period has expired</td>
      </tr>

      <tr>
        <td>22007271</td>
        <td>Information for rating is insufficient.</td>
      </tr>
      <tr>
        <td>22007272</td>
        <td>Charging failed because the required application is not supported</td>
      </tr>
      <tr>
        <td>22007278</td>
        <td>No IMEI is contained (in the situation where the product attribute is
          that a subscription relationship is bound to an IMEI).
          </td>
      </tr>
      <tr>
        <td>22007279</td>
        <td>Incorrect account status.</td>
      </tr>
      <tr>
        <td>22007281</td>
        <td>The GPRS service is locked and cannot be subscribed to temporarily.</td>
      </tr>
      <tr>
        <td>22007283</td>
        <td>To IN charging fail</td>
      </tr>
      <tr>
        <td>22007284</td>
        <td>The product has been hidden.</td>
      </tr>
      <tr>
        <td>22007286</td>
        <td>The product's subscription capability is suspended.</td>
      </tr>
      <tr>
        <td>22007301</td>
        <td>The user is suspended.</td>
      </tr>
      <tr>
        <td>22007303</td>
        <td>The user is paused.</td>
      </tr>
      <tr>
        <td>22007304</td>
        <td>The user's subscription capability is suspended and the user cannot
          subscribe to the product.</td>
      </tr>
      <tr>
        <td>22007306</td>
        <td>The user is blacklisted and cannot subscribe to the product.</td>
      </tr>
      <tr>
        <td>22007322</td>
        <td>The promotional product fails to be subscribed to in the nonpromotion period.
          </td>
      </tr>
      <tr>
        <td>22007330</td>
        <td>The account balance is insufficient.</td>
      </tr>
      <tr>
        <td>22007331</td>
        <td>The charged number is incorrect.</td>
      </tr>
      <tr>
        <td>22007332</td>
        <td>The external system charging times out.</td>
      </tr>
      <tr>
        <td>22007333</td>
        <td>Other errors occur during charging in the SDP.</td>
      </tr>
      <tr>
        <td>22007334</td>
        <td>The user information response from an externalsystem times out.
        </td>
      </tr>
      <tr>
        <td>22007336</td>
        <td>Subscription relationships fail to be synchronized to the partner.</td>
      </tr>
      <tr>
        <td>22007337</td>
        <td>The subscriber cannot subscribe to the product due to insufficient
          periodic credit times</td>
      </tr>
      <tr>
        <td>22007338</td>
        <td>The subscribed product has dependent products and cannot be
          subscribed to.</td>
      </tr>
      <tr>
        <td>22007363</td>
        <td>The users graylisted and cannot subscribe to the product.</td>
      </tr>
      <tr>
        <td>22007365</td>
        <td>The user's in chargingBlock status and the user cannot subscribe to the
          product</td>
      </tr>
      <tr>
        <td>22007505</td>
        <td>The channel is invalid. The value range of ChannelID in the request is
          invalid</td>
      </tr>
      <tr>
        <td>22007629</td>
        <td>The user is locked.</td>
      </tr>
      <tr>
        <td>22007630</td>
        <td>If the user has not subscribed to a specified channel's PPV feature or a
          bundle that the PPV feature belongs to, the user is not allowed to
          21
          subscribe to program products or program feature products of the
          channel.
          </td>
      </tr>
      <tr>
        <td>22007650</td>
        <td>Content key values are not specified.</td>
      </tr>
      <tr>
        <td>22007652</td>
        <td>The number of products that are subscribed to or unsubscribed from in
          batches exceeds the maximum.</td>
      </tr>

      <tr>
        <td>22007657</td>
        <td>The user is a corporate user and cannot subscribe to the product.</td>
      </tr>
      <tr>
        <td>22007658</td>
        <td>Subscription failed due to LinkID failure.
        </td>
      </tr>
      <tr>
        <td>22007659</td>
        <td>The temporary subscription relationship to be confirmed does not
          exist.</td>
      </tr>
      <tr>
        <td>22007660</td>
        <td>The temporary subscription relationship to be confirmed has expired.</td>
      </tr>
      <tr>
        <td>22007663</td>
        <td>The coupon cannot be applied to the product or pricing object.</td>
      </tr>
      <tr>
        <td>22007664</td>
        <td>The coupon has been used.</td>
      </tr>
      <tr>
        <td>22007665</td>
        <td>The number of times that the user uses the coupon exceeds the
          maximum.</td>
      </tr>
      <tr>
        <td>22007669</td>
        <td>The user is unauthorized, or the user is not allowed to use the service</td>
      </tr>
      <tr>
        <td>22007670</td>
        <td>The user account is in a blocked state</td>
      </tr>
      <tr>
        <td>22007671</td>
        <td>The account has expired.</td>
      </tr>
      <tr>
        <td>22007672</td>
        <td>An unknown account</td>
      </tr>
      <tr>
        <td>22007675</td>
        <td>The voucher used by the user does not match the value of operatorID
          for the user.</td>
      </tr>
      <tr>
        <td>22007683</td>
        <td>The old product or renewed product is supporting Non-Renewal
          Charging.</td>
      </tr>
      <tr>
        <td>22007696</td>
        <td>Duplicated authorization request.</td>
      </tr>
      <tr>
        <td>22007701</td>
        <td>the prepaid account of the account does not exist.</td>
      </tr>
      <tr>
        <td>22007801</td>
        <td>Token Incorrect</td>
      </tr>
      <tr>
        <td>22007802</td>
        <td>Token frequency is high
        </td>
      </tr>
      <tr>
        <td>22007804</td>
        <td>Token Expired</td>
      </tr>
      <tr>
        <td>22007805</td>
        <td>Un-successful attempts over
        </td>
      </tr>
      <tr>
        <td>22007806</td>
        <td>Service is not valid
        </td>
      </tr>
      <tr>
        <td>22007807</td>
        <td>IN system partner authentication failed.</td>
      </tr>
      <tr>
        <td>22007808  </td>
        <td>The IN system regards it as a duplicate request.</td>
      </tr>
      <tr>
        <td></td>
        <td></td>
      </tr>
      <tr>
        <td>22007819</td>
        <td>Cannot request the third-party system to perform a specified operation
          due to configuration error.
          </td>
      </tr>
      <tr>
        <td>22007823</td>
        <td>Subscription on the third-party BOSS succeeded and Subscription on
          the MDSP failed.</td>
      </tr>
      <tr>
        <td>22007825</td>
        <td>The subscription fails because the remaining validity time of the
          product is less than one period.
          </td>
      </tr>
      <tr>
        <td>22007939</td>
        <td>User's operatorID does not match with the product's operatorID.</td>
      </tr>
      <tr>
        <td>22007993</td>
        <td>Failed to invoke the interface of the SIS, IP is not in whiteIPlist.</td>
      </tr>
      <tr>
        <td>22007999</td>
        <td>Failure due to other causes.</td>
      </tr>
      <tr>
        <td>22008014</td>
        <td>The SDP fails to perform fee deduction.</td>
      </tr>
      <tr>
        <td>22999998</td>
        <td>The service returned by the external charging system is unavailable.</td>
      </tr>
      <tr>
        <td>22999999</td>
        <td>External system error.</td>
      </tr>
      <tr>
        <td>40000001</td>
        <td>User Consent Required
        </td>
      </tr>
      <tr>
        <td>40000002</td>
        <td>Privacy verification failed
        </td>
      </tr>
      <tr>
        <td>22007011</td>
        <td>product's status is abnormal
        </td>
      </tr>
      <tr>
        <td>22007900</td>
        <td>Service is hidden.
        </td>
      </tr>
      <tr>
        <td>22007920</td>
        <td>User is opt out.
        </td>
      </tr>
      <tr>
        <td>22007921</td>
        <td>Pending on bill payer
        </td>
      </tr>





       </table>

      </div>
      </div>
      </div>

