<div class="row">
  <div class="col s12 m10 l10">

    <!-- Start of first wrapper -->


    <div class="first-wrapper section scrollspy" id="generate-challenge">



<p  class="first-wrapper-title"> Get Status API</p>

<p class="first-wrapper-title sidestepA">
  1. Principles
</p>
<p>
  Bizao lets you also check the status of any transaction if you know the order_id,
This API manages two categories of parameters:
</p>

<p class="sidestepB">
  &#x2022; <b> Headers :</b> contains information letting Bizao to route your traffic by: country and
  language you target.
  <br>

  &#x2022; <b> Body-parameters :</b> contains detail on your payment transaction: amount, currency,
  used-id…
  <br>
</p>

<p class="first-wrapper-title sidestepA">2. Description</p>

<p>Bizao &#60;getstatus&#62; is a one-time API, below syntax and descriptive </p>

<p class="sidestepB">
<b> &#x2022; Api-name:</b> "getStatus" <br>
<b> &#x2022;URL:</b><br>
</p>

<span class="sidestepC">
  &#9675; &nbsp;<b>Preproduction </b>: <a href="https://preproductiongateway.bizao.com/debitCard/v2/getStatus/ORDER_ID">https://preproductiongateway.bizao.com/debitCard/v2/getStatus/ORDER_ID</a>
</span>
  <br>
  <span class="sidestepC">&#9675; &nbsp;<b>Production </b>: <a href="https://api.bizao.com/debitCard/v2/getStatus/ORDER_ID">: https://api.bizao.com/debitCard/v2/getStatus/ORDER_ID </a> </span>
<br>
<p class="sidestepB">
&#x2022; <b>Method</b>: GET
</p>

<p class="sidestepB"><b>Getstatus API query syntax</b></p>

<p> Your query will contain the following Headers:</p>

<br><br><br>
Your query will contain the following Headers:
<br><br>

<table>
  <tr>
    <td><b>Header</b> </td>
    <td><b>Description/Content</b> </td>
    <td><b>Usage</b> </td>
  </tr>
  <tr>
    <td>Authorization</td>
    <td>YOUR_ACCESS_TOKEN</td>
    <td>Mandatory</td>
  </tr>
  <tr>
    <td>content-type </td>
    <td>application/json</td>
    <td>Mandatory</td>
  </tr>

  <tr>
    <td>country-code </td>
    <td>(string)2 characters code of the country you target
      ( use this norme : ISO 3166 alpha 2 country code, url
      to get the all country-code list:
      https://www.iban.com/country-codes
      (for instance:&#60;ci&#62; is the country-code for ivory Coast.</td>
    <td>Mandatory</td>
  </tr>
</table>

<br> <br>

Getstatus query sample:

<pre>
  <div class="code" >
    GET / debitCard/v2/getStatus/&#123;order_id&#125; HTTP/1.1
    Host: api.bizao.com
    Authorization: Bearer cb422427-1wo6-3be2-b15b-sff651s7bs4e
    country-code: ci

  </div>

</pre>

<p><b> 2.2 Getstatus API response </b></p>
<p>
Bizao &#60;getStatus&#62; API will response in Json format
This Json format/response has the same format as for notification flow.
The body content will be updated according to each targeted channel: the
mandatory incoming parameters will be added accordingly
</p>

<pre>
  <div class="code" >
    &#123;
      "meta": &#123;
      "type": "payment",
      "source": "visa-mc",
      "category": "education"
      &#125;,
      "status": "Successful",
      "amount": "100.00",
      "order-id": "EB_1_bizao_test09_11_2020_04",
      "currency": "XOF",
      "reference": "testref",
      "date": "2020-11-09 14:30:04.0",
      "country-code": "ci",
      "state": "test",
      "intTransaction-id": "f8061034-cc5e-4ba6-b891-c39c64c20f1b",
      "extTransaction-id": "211"
      &#125;

  </div>

</pre>


<p class="first-wrapper-title sidestepA">3. Payment status</p>

<p>
  Below are the statuses that exist in the BIZAO HUB:
</p>
<p>
<span class="sidestepA"><b>-  INITIATED:</b> Status to be restored as soon as an initialization action occurs, The
  partner executes the North request essentially on the web channel without
  any other action.  </span> <br> <br>

  <span class="sidestepA"><b>-  INPROGRES:</b>  Status in progress or waiting for validation
    any other action.  </span> <br> <br>
  <span class="sidestepA"><b>-  FAILED:</b>  Status in failure of a transaction
  </span> <br> <br>
  <span class="sidestepA"><b>-  SUCCESSFUL:</b> Status in success of a transaction
  </span> <br> <br>
  <span class="sidestepA"><b>-  CANCELED:</b> Status to be restored, as soon as a cancellation action occurs.
    From the payment page the user clicks on the "Cancel Transaction" button
    on the payment page.
  </span> <br> <br>
  <span class="sidestepA"><b>-  EXPIRED:</b>  Status to be returned, as soon as an abandonment action occurs.
    In this use case: <br> <br>

    <span class="sidestepC">
      &#9675; Either the user closes the payment page before the transaction is
      submitted to the operator's backend (processing)
    </span> <br> <br>
    <span class="sidestepC">
      &#9675; Either the BIZAO Hub will pass the transaction to be abandoned after a
      series of verification of the status of the transaction "INPROGRESS" or
      "EXPIRED".
    </span> <br>
  </span> <br>
</p>



<p class="first-wrapper-title">4 Bizao payment API common error code</p>

<table>
  <tr>
      <td><b>ErrorCode</b> </td>
      <td><b>Exception Message Text</b> </td>
      <td><b>Description / Comments</b> </td>
      <td><b>HTTP response code</b></td>
  </tr>
  <tr>
      <td>50</td>
      <td>Forbidden access to the API</td>
      <td>Access denied by ACL.
        'Unauthorized Access Layer'
        or 'Unauthorized
        applicationId' or
        ‘Unauthorized country’</td>
      <td>403
         Forbidden</td>
  </tr>
  <tr>
      <td>1201</td>
      <td>Forbidden access to the API</td>
      <td>Forbidden transaction</td>
      <td>403
         Forbidden</td>
  </tr>
  <tr>
      <td>1202</td>
      <td>Forbidden access to the API</td>
      <td>Invalid merchant key</td>
      <td>403
         Forbidden</td>
  </tr>
  <tr>
      <td>1203</td>
      <td>Forbidden access to the API</td>
      <td>Unauthorized currency for this
         country</td>
      <td>403
         Forbidden</td>
  </tr>
  <tr>
      <td>1204</td>
      <td>Forbidden access to the API</td>
      <td>Order Already exists. The
         <b><i>order_id</i></b> must be unique in the
         system. <br> Only one <b><u>Token per
             order_id</u></b></td>
      <td>403
         Forbidden</td>
  </tr>
</table>





    <!-- End of fourth wrapper -->


</div>
