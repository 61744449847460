import { Component, OnInit } from '@angular/core';
declare var jQuery: any
@Component({
  selector: 'app-orange-ussd-store',
  templateUrl: './orange-ussd-store.component.html',
  styleUrls: ['./orange-ussd-store.component.css']
})
export class OrangeUssdStoreComponent implements OnInit {
  constructor() { }

  ngOnInit(): void {
    (function ($) {

      $(document).ready(function(){
       $('.scrollspy').scrollSpy();
       $('.row .table-of-contents').pushpin({ top: 80 });
     });
   })(jQuery);
  }

}
