import { Component, OnInit } from '@angular/core';

declare var jQuery: any;
@Component({
  selector: 'app-mtn-subscription-api',
  templateUrl: './mtn-subscription-api.component.html',
  styleUrls: ['./mtn-subscription-api.component.css']
})
export class MtnSubscriptionAPIComponent implements OnInit {
  constructor() { }

  ngOnInit(): void {

    (function ($) {

      $(document).ready(function(){
       $('.scrollspy').scrollSpy();
       $('.row .table-of-contents').pushpin({ top: 80 });
     });
   })(jQuery);

  }

}
