import { Component, OnInit } from '@angular/core';
import { FetchDataService } from 'src/app/services/fetch-data.service';
import { environment } from 'src/environments/environment';
declare var jQuery: any;
@Component({
  selector: 'app-bulkquery-api',
  templateUrl: './bulkquery-api.component.html',
  styleUrls: ['./bulkquery-api.component.css']
})
export class BulkqueryApiComponent implements OnInit {

  constructor(private fetchDataService : FetchDataService) { }


  //find 5 day before date .
// Source: https://stackoverflow.com/a/1296374 .

key=""

  changeTobulk()
  {
    this.key="bulk"
  }

  changeToGetStatusApi()
  {
    this.key="GetStatusApi"
  }

  changeToGetBalanceApi()
  {
    this.key="GetBalanceApi"
  }



  ngOnInit(): void {

    this.key="bulk"

    var d = new Date();

    console.log(d.toLocaleDateString())
 d.setDate(d.getDate()-5);

 console.log(d.toLocaleDateString())


  }

}

