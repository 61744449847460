<div class="row">
  <div class="col s12 m10 l10">

    <!-- Start of first wrapper -->


    <div class="first-wrapper section scrollspy" id="generate-challenge">

      <br>
      <!-- Start of Principles Section -->
      <p class="first-wrapper-title">Introduction</p>
      <p>
        Bizao gateway gives you access to a large panel of services throughRESTAPIs. Bulkpayment is one of these
        services. <br>
        This service is based on a <span class="light-span">Bulk</span>northbound API that aggregates and covers <span
          class="light-span">multiple countries</span> and <span class="light-span">operators</span>. <br>
        countries and operators.
        This API allows you to do Cashin to different recipients with just one click through
        Bizao Hub based on the information provided in your request.
        <br>
        This API lets you target the right <span class="light-span">country</span>, <span
          class="light-span">operator</span> by using the dedicated Header for that. The onboarding of <span
          class="light-span">Bulk</span> partners is covered in detail in this document.

      </p>


      <!-- End of Principles section -->

      <!-- Start of Generate challenge section -->
      <br>

      <p class="first-wrapper-title">2.Your ACCESS_TOKEN </p>
      <p>The ACCESS_TOKEN is generated offline and sent to the IRT via a suitable chanel.</p>
      <br>



      <p class="first-wrapper-title">3. Partner onboarding:</p>
      <p> For <span class="light-span">&#60;Bulk&#62;</span> , we have two additional steps apart the access token:

      </p>

      <p> 1 - We will follow the same step as the transfer to create the partner id and the security token for the
        authentication </p>
      <p> 2 - We will create Three virtual wallets for the partner </p>
      <p style="margin-left:10% ;"><b>a.</b> one called <span class="light-span">&#60;MainAccount&#62;</span> where we credit the balance of the partner in
        Bizao hub. Transactions will debit this account. It is mandatory to have enough balance in this wallet for a
        transaction to be processed correctly. It is necessary to have a dedicated profile to be able to credit this
        account via a GUI portal.
        All transactions there are typed (via the parameter operationType) as <span class="light-span"> credit, debit, refund or advance </span>.</p>

      <p style="margin-left:20% ;"> <b>i.</b> <span class="light-span">operationType= credit:</span> means it is a classical and normal operation of
        crediting an account</p>

      <p style="margin-left:20% ;"> <b>ii.</b> <span class="light-span"> operationType= debit:</span> means it is a classical and normal operation of
        debiting an account</p>

      <p style="margin-left:20% ;"> <b>iii.</b> <span class="light-span"> operationType =advance </span> means it is a loan possibility accorded to the
        partner. This operation can only be done in the <span class="light-span">&#123; advancedAccount &#125;</span>
        and is like a counter which limits the maximum loan amount for a service provider. If the advancedAccount is set
        to 100.000 for an SP, this means that SP can have a loan till 100.000. In other word, the mainAccount
        can be decreased till (-100.000) but not lower.</p>

      <p style="margin-left:20% ;"> <b>iv.</b>  <span class="light-span">operationType =refund: </span>: means it is an operation of credit (or debit) to
        (from) the main account to adjust an error.</p>

      <p style="margin-left:10% ;"><b>b.</b><span class="light-span">&#60;fees&#62;</span> wallet where Bizao collects all the fees gained from transactions
        processed by this partner. This wallet is only for internal use.In order to be able to collect the fees, it is
        mandatory to setup the fees calculation process in the Bizao system. Based on the fees, Bizao system should be
        able to generate
        invoices.</p>

      <p style="margin-left:10% ;"><b>c.</b> <span class="light-span">&#60;advancedAccount&#62; </span>is used to set a loan possibility accorded to the partner</p>

      <br>

      <p class="first-wrapper-title">4.BULK API</p>

      <p style="margin-left: 5%;" class="first-wrapper-title">4.1 Principles</p>

      <p style="margin-left: 10% ;">
        This API allows you to do Cashin in different recipients with just oneclickthrough BizaoHub based on the
        information provided in your request.
        This API manages three categories of parameters:
      </p>

      <p style="margin-left: 10% ;">
        <span class="light-span">Headers: </span> contains information letting Bizao to route your traffic by country, operator, channel and type you
        target.
      </p>
      <p style="margin-left: 10% ;"> <span class="light-span">Body-parameters </span> contains detail on your bulk: beneficiary details, amount, currency,
        destination country, destination wallet, etc…</p>
      <p style="margin-left: 10% ;"><span class="light-span">Static-parameters: </span> this category of parameter cover all parameters that are static
        per merchant for all its payment traffic (Notification-Fees rules,sender details, …). These parameters will be
        provisioned in Bizao Hub (in SignUP step) per Partner and will be used by Bizao-Hub in the transfer process.</p>


      <p style="margin-left: 5%;" class="first-wrapper-title">4.2 Description</p>

      <p>   Bizao bulk isss a restful API, based on json. </p>

      <p style="margin-left: 10%;"> <b>Api-name:</b> <span class="light-span">“bulk/v1”</span></p>
      <p style="margin-left: 10%;"><b>URL </b></p>
      <p style="margin-left: 20%;"><b>Preproduction :</b> <span class="light-span"> https://preproduction-gateway.bizao.com </span></p>
      <p style="margin-left: 20%;"><b>Production :</b> <span class="light-span"> https://api.bizao.com </span></p>
      <p style="margin-left: 10%;"><b>Method:</b> <span class="light-span"> POST </span></p>


      <p style="margin-left: 10%;" ><b>4.2.1 bulkpayment API query syntax</b>

      <br><br><br>
      Your query will contain the following Headers:
      <br><br>
      <table>
        <tr>
          <td><b>Header</b> </td>
          <td><b>Description/Content</b> </td>
          <td><b>Usage</b> </td>
        </tr>
        <tr>
          <td>Authorization</td>
          <td>YOUR_ACCESS_TOKEN</td>
          <td>Mandatory</td>
        </tr>
        <tr>
          <td>country-code</td>
          <td>(string)2 characters code of the country you target
            ( use this norme : ISO 3166 alpha 2 country code, url
            to get the all country-code list:
            https://www.iban.com/country-codes
            (for instance:&#60;ci&#62; is the country-code for ivory Coast.</td>
          <td>Mandatory</td>
        </tr>
        <tr>
          <td>channel</td>
          <td>The type of targeted channel using one of keyword:
            &#60;web&#62;: </td>
          <td>Mandatory</td>
        </tr>

        <tr>
          <td>type</td>
          <td>The type is set to <span class="light-span">bulk </span></td>
          <td>Mandatory</td>
        </tr>
        <tr>
          <td>lang</td>
          <td>the abbreviation in 2 characters of targeted
            language for the request, In ISO 639 (alpha-2) format</td>
          <td>Mandatory</td>
        </tr>
        <tr>
          <td>content-type </td>
          <td>application/json</td>
          <td>Mandatory</td>
        </tr>
      </table>

      <br> <br>

     <p> The body of your query will contain the parameters below into a <b><i>“challenge” object (JSON)</i></b> : </p>

     <table>
      <tr>
        <td><b>Parameter Name</b> </td>
        <td><b>Description</b> </td>
        <td><b>Usage</b> </td>
      </tr>
      <tr>
        <td>currency</td>
        <td>(string) currency identifier as defined in [ISO4217]. Note
          (as described for the amount parameter) that either
          currency and amount or code must be specified. you
          can use this site to know the currency-code by country:
          https://fr.iban.com/currency-codes.html</td>
        <td>Mandatory</td>
      </tr>
      <tr>
        <td>reference</td>
        <td>Reference to the bulkpayment client</td>
        <td>Mandatory 30 char max</td>
      </tr>
      <tr>
        <td>batchNumber</td>
        <td>(string) identifies this create payment request. This field
          SHOULD be present. <span class="light-span">IT MUST BE UNIQUE FOR THE SYSTEM. </span></td>
        <td>Mandatory</td>
      </tr>

      <tr>
        <td>state</td>
        <td>Parameter up to merchant to set within any value he
          wants to keep over all payment transaction processing;
          this field must be in Encodeded-URL (Bizao do not
          alternate/update this value and send it back within
          payment response/notification)</td>
        <td>Mandatory</td>
      </tr>
      <tr>
        <td>beneficiary</td>
        <td>Object &#60;customer&#62;</td>
        <td>Mandatory</td>
      </tr>

    </table>

      <br><br><br>

      <p> <u> Customer object</u></p>

      <div class="sample-wrapper">
        <pre>
<div class="code" >
 <b> &#60;&#60; customer::Object &#62;&#62; </b>
  id <span style="font-size: 12px;"> <i>//lock number</i></span> <br>
  beneficiaryFirstName <span style="font-size: 12px;"> <i>//customer first name</i></span>
  beneficiaryLastName <span style="font-size: 12px;"> <i> //customer last name </i></span>
  beneficiaryAddress <span style="font-size: 12px;"> <i>//customer address</i> </span>
  beneficiaryMobileNumber <span style="font-size: 12px;"> <i> //customer msisdn </i> </span>
  amount /<span style="font-size: 12px;"> <i>//customer address Amount to transfer to the beneficiarywallet </i></span>
  feesApplicable<span style="font-size: 12px;"> <i>//(YES or NO) boulean parameter to know if the fees is applicable or not mno // MNO name </i></span>
              </div>
              </pre>

        <br>

        <p> <b> <u> bulkpayment query sample:</u> </b></p>

        <pre>
          <div class="code" >
POST bulk/v1/ HTTP/1.1
Host: api.bizao.com
<b>Authorization:</b> Bearer token
<b>Content-Type:</b> application/json
<b>country-code:</b> sn
<b>lang:</b>fr
<b>channel:</b>web
<b>type:</b> Bulk


--d
&#123;
  "currency": "XOF",
  "reference": "bsc-bulk-mt-XOF",
  "batchNumber": "bulk-sn_07-10-2021_01",
  "data": [
  &#123;
      "id": "001",
      "beneficiaryFirstName": "Abdou",
      "beneficiaryLastName": "Diop",
      "beneficiaryAddress": "",
      "beneficiaryMobileNumber": "221761234567",
      "amount": 10,
      "feesApplicable": "YES",
      "mno": "free"
    &#125;,
    &#123;
      "id": "002",
      "beneficiaryFirstName": "Logan",
      "beneficiaryLastName": "Doo",
      "beneficiaryAddress": "Rue 10 angle 20, Medina, Dakar",
      "beneficiaryMobileNumber": "2217754566666",
      "amount": 1,
      "feesApplicable": "YES",
      "mno": "orange"
    &#125;
  ]
&#125;
                        </div>
                        </pre>


            <p style="margin-left: 10%;" ><b>4.2.2 Bulk API Response</b></p>

        <p>Bizao <span class="light-span">&#60;bulk&#62;</span> API will response in Json format
          This Json format/response has two different parties </p> :
          <p>The meta bloc containing the common details( reference, batchNumber,
          bulkpayment customer informations, …) </p>
          <p> The second bloc(<b>data</b>) contain the specific data for the beneficiary </p>


          <br> <br> <br>

          <p><b>Response query</b> </p>

          <pre>
            <div class="code" >
&#123;
"meta": &#123;
"source": "bizao", "merchant-name": "bizao-bsc@carbon.super",
&#123;
"type": "bulk",
"currency": "XOF",
"batchNumber": "bulk-sn_07-10-2021_01",
"reference": "bsc-bulk-mt-XOF",
"feesType": "FIXED_FEE",<span style="font-size: 12px;"> <i>//Type of fees applicable for this bulk payment customer</i></span>
"lang": "fr",
"totalAmount": 11.00,  <span style="font-size: 12px;"> <i>//Total principal amount in the batch</i> </span>
"totalFees": 2.00, <span style="font-size: 12px;"> <i>//Total fees amount in the batch</i></span>

  <span style="font-size: 14px; color: cornflowerblue;"> <i>//Bulkpayment customer details</i></span>
"senderFirstName": "Damith",
"senderLastName": "Sulochana",
"senderAddress": "Colombo",
"senderMobileNumber": "2250512345678",
"fromCountry": "cm",

<span style="font-size: 14px; color: cornflowerblue;"> <i>//END Bulkpayment customer details </i></span>
"comment": "Bulk Process will take minimum 0.28 minutes"
&#125;,
"data": [
&#123;
"id": "001",
 "orderId": "bulk-sn_07-10-2021_01-001",
 "mno": "free",
 "beneficiaryFirstName": "Salif",
  "beneficiaryLastName": "KA",
  "beneficiaryAddress": "",
  "beneficiaryMobileNumber": "221765151504". The international mobile number without 00 or +
  "toCountry": "sn",
  "feesApplicable": "Yes",
  "amount": 10.00,
  "fees": 1.00,
  "status": "Pending",
  "intTransaction-Id": "1aa27e52-969b-4fd1-8012-da5606b8de71", //thetransactionidfrom BIZAO system
  "extTransaction-Id": ""<span style="font-size: 12px;"><i>//the transaction id from the MNO system &#125;,</i></span>
&#123;
"id": "002",
"orderId": "bulk-sn_07-10-2021_01-002",
"mno": "orange",
"beneficiaryFirstName": "Logan",
"beneficiaryLastName": "Doo",
"beneficiaryAddress": "Rue 10 angle 20, Medina, Dakar",
"beneficiaryMobileNumber": "221775782348",
"toCountry": "sn",
"feesApplicable": "Yes",
"amount": 1.00, "fees": 1.00,
"status": "Pending",
"intTransaction-Id": "4be22560-389b-4c69-9e45-e6aa7452b7dd",<span style="font-size: 12px;"><i>//the transaction id fromBIZAO system</i></span>
"extTransaction-Id": ""<span style="font-size: 12px;"><i>//the transaction id from the MNO system &#125;</i></span>
  ]

                          </div>
                          </pre>

                          <p style="margin-left: 5%;" class="first-wrapper-title">4.3 getBalanceInfo</p>

<p> This service is very important for Northbound partners. It will help them to know the remaining balance in their virtual wallet merchantAccount(<span class="light-span">main wallet</span>) in BIZAO system. </p>
<p> Each partner has its own dedicated  main wallet. So, it is necessary to have the partner id to be able to display the correct balance for a given partner. </p>

<pre>
  <div class="code" >
Get /bulk/v1/<b>getBalanceInfo/accountName</b> HTTP/1.1 Host:
api.bizao.com
Authorization: Bearer token
type: bulk

                </div>
                </pre>

<pre>
  <div class="code" >
    <u><b>Samples Responses:</b></u>


    &#123;
    "meta": &#123;
    "source": "bizao",
    "type": "bulk"
    &#125;,
    "accounts": [

    &#123;
      "account_number": "bsc2001",<span style="font-size: 12px;"><i>// Account number</i></span>
      "status": "Active",<span style="font-size: 12px;"><i>// Account status</i></span>
      "currency": "XOF",
      "balance": 9767.00,<span style="font-size: 12px;"><i>//total balance in the system</i></span>
      "reserved_amount": 43.00,<span style="font-size: 12px;"><i>//reserved amount for a batch not treated yet</i></span>
      "loan_balance": 0.00,
      "advance_amount": 0.00,
      "turnover_balance": 6.00,
      "settlement_balance": 0.00


    &#125;
    ]
    }

    </div>
    </pre>

<br> <br>
<p style="margin-left: 5%;" class="first-wrapper-title">4.4 . Notification flow</p>

   <p> Bizao bulk is an asynchronous API. For each bulk payment, there are two types of notification: </p>
   <br>

 <p><span class="light-span">B2C-Notif</span>: this category of notification is for the beneficiary. For each successful
transfer, the mobile operator will send him an SMS with the details of the transfer.</p>

 <p> <span class="light-span">B2B-Notif</span>: this category of notification is for the bulkpayment customer backend. For each batch of transfers, based on the backend configuration (Asynch), Bizao will
notify the partner backend (using the partner-Callback when necessary) with the
final status of the payment transaction. </p>

<br>

<p> Below a sample of notification content:</p>


<pre>
<div class="code" >

  &#123;
  "meta": &#123;
  "source": "bizao",
  "merchant-name": "bizao-bsc@carbon.super",
  *"type": "bulk",
  "currency": "XOF",
  "batchNumber": "bulk-sn_07-10-2021_01",
  "reference": "bsc-bulk-mt-XOF",
  "feesType": "FIXED_FEE",
  "lang": "fr",
  "totalAmount": 11.00,
  "totalFees": 2.00,
  "senderFirstName": "Damith",
  "senderLastName": "Sulochana",
  "senderAddress": "Colombo",
  "senderMobileNumber": "2250512345678",
  "fromCountry": "cm"
  &#125;, "data": [
  &#123;
  "id": "001",
   "orderId": "bulk-sn_07-10-2021_01-001",
   "mno": "free", "beneficiaryFirstName": "Salif",
    "beneficiaryLastName": "KA",
    "beneficiaryAddress": "",
    "beneficiaryMobileNumber": "221765151504",
    "toCountry": "sn",
    "feesApplicable": "Yes",
    "amount": 10.00,
    "fees": 1.00,
    "status": "Successful",
     "intTransaction-Id": "1aa27e52-969b-4fd1-8012-da5606b8de71",
     "extTransaction-Id": "CI211007.1411.A95748"
  &#125;,
  &#123;
  "id": "002",
  "orderId": "bulk-sn_07-10-2021_01-002",
  "mno": "orange",
  "beneficiaryFirstName": "Logan",
  "beneficiaryLastName": "Doo",
  "beneficiaryAddress": "Rue 10 angle 20, Medina, Dakar",
  "beneficiaryMobileNumber": "221775782348",
  "toCountry": "sn",
  "feesApplicable": "Yes",
  "amount": 1.00,
  "fees": 1.00,
  "status": "Failed",
  "failedReason": "Validation failed, please contact support",
  "intTransaction-Id": "4be22560-389b-4c69-9e45-e6aa7452b7dd",
  "extTransaction-Id": ""
  &#125;
  ]
  &#125;

  </div>
  </pre>


  <p>Bizao transfer API common error code </p>





  <table>
    <tr>
        <th><b>Error Code</b> </th>
        <th><b>API Reponses Error Message</b></th>
        <th><b>Error Description. </b></th>

    </tr>
    <tr>
        <td>BMT10001</td>
        <td>Missing mandatory header parameter: m
          no-name
          </td>
        <td>mno-name header is missing
        </td>

    </tr>
    <tr>
        <td>BMT10002</td>
        <td>Missing mandatory header parameter: co
          untry-code  </td>
        <td>country-code header is missing </td>
    </tr>
    <tr>
        <td>BMT10003</td>
        <td>Missing mandatory header parameter: channel
          </td>
        <td>channel header is missing </td>
    </tr>
    <tr>
        <td>BMT10004</td>
        <td>Missing mandatory parameter: amount </td>
        <td>Transfer amount is not provided</td>

    </tr>
    <tr>
        <td>BMT10005</td>
        <td>Amount cannot contain cents</td>
        <td>Invalid amount, amount is provided with ce
          nts</td>

    </tr>
    <tr>
        <td>BMT10006</td>
        <td>Amount should be in number</td>
        <td>Provided amount consist some character o
          r special signs</td>

    </tr>
    <tr>
        <td>BMT10007</td>
        <td>Missing mandatory parameter: beneficiar
          yMobileNumber </td>
        <td>BeneficiaryMobileNumber is not provided</td>

    </tr>
    <tr>
        <td>BMT10008</td>
        <td>senderMobileNumber and beneficiaryMo
          bileNumber must not be same.</td>
        <td>User has pass same MSISDN
          for sender and beneficiary.</td>
    </tr>
    <tr>
        <td>BMT10009</td>
        <td>Beneficiary number does not
          match with MNO number format.
          </td>
        <td>Invalid MSISDN format for MNO and
          country</td>
    </tr>
    <tr>
        <td>BMT10010</td>
        <td>Missing mandatory parameter: currency</td>
        <td>Currency for transfer is not provided.</td>

    </tr>
    <tr>
        <td>BMT10011</td>
        <td>Missing mandatory parameter: reference </td>
        <td>Merchant reference for
          the currency account Is not provided</td>

    </tr>


    <tr>
      <td>BMT10012</td>
      <td>Missing mandatory parameter: order_id
        </td>
      <td>Order id for request is not provided.</td>

  </tr>
  <tr>
      <td>BMT10013</td>
      <td>Missing mandatory header parameter: lang</td>
      <td>Lang header is missing.
      </td>
  </tr>
  <tr>
      <td>BMT10014</td>
      <td>Missing mandatory header parameter: US
        ER_ID </td>
      <td>Missing USER_ID parameter which send fro
        m Api Manager</td>
  </tr>
  <tr>
      <td>BMT10015</td>
      <td>Missing mandatory header parameter: sta
        te
        </td>
      <td>State is not provided in the request
      </td>

  </tr>
  <tr>
      <td>BMT10016</td>
      <td>Missing mandatory header parameter :
        type </td>
      <td>Type header is missing </td>

  </tr>
  <tr>
      <td>BMT10017</td>
      <td>Missing mandatory header parameter :
        type </td>
      <td>When type parameter type other than wall
        et or bulk</td>

  </tr>
  <tr>
      <td>BMT10018</td>
      <td>Invalid header parameter: channel </td>
      <td>When the channel parameter is having unr
        ecognized values
        </td>

  </tr>
  <tr>
      <td>BMT11000</td>
      <td>Duplicate Order ID</td>
      <td>Order ID is already used.
      </td>
  </tr>
  <tr>
      <td>BMT11001</td>
      <td>You
        have successful transaction recently for sa
        me amount, same sender and same benef
        iciary.</td>
      <td>Request has already success for same bene
        ficiary/sender/mount/currency/mno</td>
  </tr>
  <tr>
      <td>BMT11002</td>
      <td>No matching account for the request</td>
      <td>Cannot find Service provider provisioning
        for requested currency and reference.
        </td>

  </tr>
  <tr>
      <td>BMT11003</td>
      <td>Invalid service provider name: %s
        or merchant name does not
        match with service provider name. </td>
      <td>Invalid Service Provider
      </td>

  </tr>





  <tr>
    <td>BMT11004</td>
    <td>Fee rule not found for
      the transfer. Please contact support. </td>
    <td>Fee rule has not been provisioned for
      the currency and SP.</td>

</tr>
<tr>
    <td>BMT11005</td>
    <td>Order does not exist for given order ID</td>
    <td>Invalid or not existing Order ID
    </td>

</tr>
<tr>
    <td>BMT11006</td>
    <td>Insufficient account Balance </td>
    <td>Insufficient account balance </td>

</tr>
<tr>
    <td>BMT00000</td>
    <td>Validation failed. Please contact support.</td>
    <td>General Error</td>
</tr>

<tr>
  <td></td>
  <td></td>
  <td></td>
</tr>
<tr>
    <td>BMT21000</td>
    <td>Can't find the mt_bulk settings conf</td>
    <td>Cannot find the bulk account no</td>
</tr>
<tr>
  <td>BMT21001</td>
  <td>Exceeded the bulk transaction
    record limit </td>
  <td>Cannot find the bulk settings configuration
    for id = 1
    </td>
</tr>
<tr>
    <td>BMT21002</td>
    <td>Can't find bulk account no </td>
    <td>Merchant is not provisioned or invalid mer
      chant reference</td>

</tr>
<tr>
    <td>BMT21003</td>
    <td>Date format exception yyyy-mm-dd </td>
    <td>For the request parameters, date
      format should be as “yyyy-mm-dd"</td>

</tr>


<tr>
  <td>BMT20004</td>
  <td>Number format exception
    </td>
  <td>Beneficiary msisdn is not
    match with the mno number format. </td>

</tr>
<tr>
  <td>BMT20005</td>
  <td>Can't find the sender msisdn </td>
  <td>Merchant information is invalid or not
    provision the sender msisdn. </td>
</tr>
<tr>
  <td>BMT20006</td>
  <td>Same sender and beneficiary msisdn </td>
  <td>Merchant information is invalid or not
    provision the sender msisdn. </td>
</tr>
<tr>
  <td>BMT20007</td>
  <td>Can't find the information
    for given order id.
    </td>
  <td>Invalid order id.
  </td>

</tr>
<tr>
  <td>BMT20008</td>
  <td>Can't find the bulk accounts for given sp</td>
  <td>Invalid geven parameters</td>

</tr>
<tr>
  <td>BMT20009</td>
  <td>Request items
    have missing mandatory properties. </td>
  <td>Request items
    have missing mandatory properties</td>

</tr>
<tr>
  <td>BMT20010</td>
  <td>Transaction amount exceeded to current
    balance. </td>
  <td>Transaction amount exceeded to current b
    alance.</td>

</tr>
<tr>
  <td>BMT20011</td>
  <td>No records found for the given criteria. </td>
  <td>No records found for the given criteria.
  </td>
</tr>
<tr>
  <td>BMT20012</td>
  <td>date format error</td>
  <td>Too many retries</td>
</tr>
<tr>
  <td>BMT20013</td>
  <td>When the request parameter is not on
    the declared date format.
    </td>
  <td>Error while executing background
    processor. </td>

</tr>


</table>


    <!-- End of fourth wrapper -->


</div>
