<div class="row">
  <div class="col s12 m6 l6 ">
      <p class="section-subtitle ">APIS</p>
      <hr style="border-color: #E3E8EE; border-style: solid;">
      <ul class="bar">
          <li><a routerLink="#" [ngClass]="{'is-active' : key === 'genOTP'}" (click)="changeKeyToBulkQuery()">Bulk Payment Query</a></li>
          <li><a routerLink="#" [ngClass]="{'is-active' : key === 'valOTP'}" (click)="changeKeyToGetBalance()">GetBalance Info</a></li>

      </ul>
  </div>
  <div class="col s12 m6 l6 ">
      <p class="section-subtitle">Description</p>
      <hr style="border-color: #E3E8EE; border-style: solid;">
      <p *ngIf="key === 'bulkquery'">This API allows you to do Cashin in different recipients with just oneclickthrough BizaoHub based on the information provided in your request. </p>
      <p *ngIf="key === 'getBalance'">This service is very important for Northbound partners. It will help them to know the remaining balance in their virtual wallet merchantAccount(main wallet) in BIZAO system. </p>

  </div>
</div>
<hr style="border-color: #E3E8EE; border-style: solid;">

<div *ngIf=" key === 'bulkquery' ">
<app-bulkquery-api></app-bulkquery-api>
</div>
<div *ngIf=" key === 'getBalance' ">
  <app-get-balance-api></app-get-balance-api>
</div>

