<div class="row">
    <div class="col s12 m10 l10">
        <!-- Start of first wrapper -->
        <div class="first-wrapper section scrollspy" id="principles">
            <p class="first-wrapper-title">MTN-Unsubscription API</p>
            <p class="first-wrapper-title">1. Principles</p>
            <p>With MTN there is two way to UnSubscrib the User :</p>
            <p>&#x2022;&nbsp; <b>Unsubscription via Bizao ShortCode </b> :
                Bizao propose this <b><i><u>SC 8721</u></i></b> that users can use with the right Keyword to UnSubscribe
                to the Product <br> via SMS :
                <span class="light-span"><code>“<b>STOP</b>&#x2039;Keyword”&#x203A;</code></span>,
                <span>where <span class="light-span"><code>"Keyword"</code></span> is the one committed with Bizao for
                    targeted Product.</span></p>
            <p>&#x2022;&nbsp; <b>Unsubscription via Bizao API :</b>
                Bizao offers also an API to let the SP unSubscribe
                the User. see description in below paragraph below.</p>
            <br>
            <p class="first-wrapper-title">2. Description</p>
            <p>To unsubscribe the user via API, you can use the same subscription API but in
                <span class="code"><code>format/syntax</code></span> below.
                You keep the same Header used for the Subscription API.</p>
            &#x2022; <span class="keys">BIZAO-HOST</span> : <span class="code"><code>api.bizao.com</code></span>
            <br><br>
            &#x2022; <span class="keys">BIZAO-URI</span> : <span class="code"><code>subscription/v1</code></span>
            <p><b><i>Syntax</i></b> : <span>&#x28;DELETE&#x29; subscription/v1/&#x7b;subscriptionID&#x7d; &#x28;see required headers
                below&#x29;</span></p>
          <p>This API use only a Headers, <b>No body needed for this API.</b></p>
          <p>Below the following <b><i>Headers</i></b> required in your query :</p>
          <br>

          <table>
              <tr>
                  <td><b>Header</b></td>
                  <td><b>Description/Content</b></td>
              </tr>
              <tr>
                  <td>Authorization</td>
                  <td>YOUR_ACCESS_TOKEN</td>
              </tr>
              <tr>
                  <td>mno-name</td>
                  <td>(string) the name of operator you target. Example :
                    <b>orange, mtn</b>. <br> Bizao will update you regarding the new
                    mno set in the hub.</td>
              </tr>
              <tr>
                  <td>country-code</td>
                  <td>(string) 2 characters code of the country you target
                    ( use this norme : <br> ISO 3166 alpha 2 country code, url to
                    get the all country-code list : <br>
                    <pre><code>https://www.iban.com/country-codes</code></pre> (for example CI is
                    the country-code for ivory Coast.)</td>
              </tr>
              <tr>
                  <td>state</td>
                  <td>
                    (alphanum) parameter to let put some correlation data
                    <b>Note</b> : this parametre must be <b>in EncodedURL format</b>. <br>
                    Bizao Will sent you back the value of this parametre
                    wihtin the B2B notification
                  </td>
              </tr>
          </table>
        </div>
        <!-- End of first wrapper -->

        <!-- Start of second-wrapper -->
        <div class="second-wrapper section scrollspy" id="samples">
            <p class="first-wrapper-title">3. Query Samples</p>
            <span class="light-span">&#x2022; Unsubscription query sample: <br></span>
            <pre>
            <div class="code">
DELETE /subscription/v1/0d658cdd-caaa-40d2-a082-fe112f81aa71 HTTP/1.1
Host: api.bizao.com
Authorization: Bearer cb4sd2427-1eax-3be2-b1xw-145sdç3zr
country-code: cm
mno-name: mtn
Content-Type: application/x-www-form-urlencoded
state: xxx //en URL encoding format
Cache-Control: no-cache

            </div>
            </pre>
            <br>
           <span class="light-span"> &#x2022; Successful real time response sample (the http response code is 200) : </span>
            <pre>
            <div class="code">
&#123;
    "UnSubscription- id": "55564763-6425-441d-95ef-34a36971df5c",
    "Unsubscription- status": " Successful "
&#125;
            </div>
            </pre>
        </div>
        <!-- End of second wrapper -->





    </div>
    <div class="col hide-on-small-only s12 m2 l2">
        <ul class="section table-of-contents">
            <li>
                <p class="toc-title">On this page</p>
            </li>
            <li><a href="#principles">Principles</a></li>
        </ul>
    </div>


</div>
