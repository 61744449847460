import { Component, OnInit } from '@angular/core';
declare var jQuery: any;

@Component({
  selector: 'app-left-side-navigation',
  templateUrl: './left-side-navigation.component.html',
  styleUrls: ['./left-side-navigation.component.css']
})
export class LeftSideNavigationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    
    // semantic-ui dropdown controller
    (function ($) {
      $('.dropdown-trigger').dropdown({
        alignement:'left',
        coverTrigger:true
      });
    })(jQuery); 

    // materializecss modal controller
    (function ($) {
      $(document).ready(function(){
        $('.modal').modal();
      });
    })(jQuery);

    //sticky nav
    /* (function ($) {
      $(document).ready(function(){
        $(window).scroll(function(){
      if($(window).scrollTop()>300){
         $('#ul_left_nav').addClass('sticky-nav');
          $('#nav-mobile').addClass('move_right');
         $('#brand-logo').addClass('set_right'); 
       }
       else {
        $('nav').removeClass('sticky-nav');
        $('#nav-mobile').removeClass('move_right');
        $('#brand-logo').removeClass('set_right'); 
       }
      })}
      )
    })(jQuery); */
}
}