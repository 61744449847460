import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FetchDataService } from 'src/app/services/fetch-data.service';
declare var jQuery: any;
@Component({
  selector: 'app-money-transfer-api',
  templateUrl: './money-transfer-api.component.html',
  styleUrls: ['./money-transfer-api.component.css']
})
export class MoneyTransferApiComponent implements OnInit {

  constructor(private fetchDataService : FetchDataService) { }


  //find 5 day before date .
// Source: https://stackoverflow.com/a/1296374 .

key=""

  changeTomoneyTransfert()
  {
    this.key="moneyTransfert"
  }

  changeToGetBalanceApi()
  {
    this.key="GetBalanceApi"
  }
  changeToGetStatusApi()
  {
    this.key="GetStatusApi"
  }



  ngOnInit(): void {

    this.key="moneyTransfert"

    var d = new Date();

    console.log(d.toLocaleDateString())
 d.setDate(d.getDate()-5);

 console.log(d.toLocaleDateString())


  }

}

