<div class="row">
  <div class="col s12 m10 l10">

    <!-- Start of first wrapper -->


    <div class="first-wrapper section scrollspy" id="generate-challenge">

      <br>
      <!-- Start of Principles Section -->
      <p class="first-wrapper-title">Introduction</p>
      <p>
        Bizao gateway gives you access to a large panel of services through REST APIs.
        Money Transfer is one of these services. <br>
        This service is based on a <b>&#60;Moneytransfert&#62;</b> northbound API that aggregates and covers <span
          class="light-span">multiple countries</span>,<span class="light-span">operators</span>, and <span class="light-span">payment type</span>
<br>

        Right now, our money transfer API manages and covers below transfer types:
        </p>
<p class="pointTitle">
       <strong> Cash:</strong> dedicated to a classical remittance like Western Union, Wari, RIA,
        MoneyGram etc. (Remittance).
</p>
<p class="pointTitle">
  <strong>Wallet:</strong> dedicated to all Wallet deposit (Cashin).
</p>
<p class="pointTitle">
  <strong>Bank:</strong> dedicated to bank deposit
</p>


<p>
  This API lets you target the right <span class="light-span">country , operator, channel, et type </span> by using the dedicated
Header for that.
</p>
<p>
  The onboarding process of partners (IRT) is out of the scope of this this document. Our
  integration will be in charge to manage this step offline.
</p>

<p>

  <strong>For this version, only the type Wallet is covered.</strong> Other types will be covered in further
versions.
</p>


      <!-- End of Principles section -->

      <!-- Start of Generate challenge section -->
      <br>

      <p class="first-wrapper-title">2.Your ACCESS_TOKEN </p>
      <p>The ACCESS_TOKEN is generated thanks to your <i>CLIENT_ID</i> and <i>CLIENT_SECRET</i> and must be
        present in the header of all your calls to our APIs.</p>

        <p>
          <b class="pointTitle">Sample of ACCESS_TOKEN: </b>
          <br>
          <i class="pointTitle">“4qa1bae4-3f9b-346-9t8b-c0e4d4ef”</i>
        </p>

        <p>
          You will find more information about how to generate and how to use your <i>ACCESS_TOKEN</i>
          here: <a href="https://dev.bizao.com/docs/getting-started/generate-a-bizao-access_token/">https://dev.bizao.com/docs/getting-started/generate-a-bizao-access_token/ </a>
        </p>

      <br>



      <p class="first-wrapper-title">3. Partner onboarding:</p>
      <p> The partner onboarding is set offline. After the onboarding, the partner will have a virtual account
        in BIZAO system and credentials are sent by the most suitable channel(email, SMS, etc.).
      </p>

      <br>

      <p class="first-wrapper-title">4.&#60;moneytransfer&#62; API</p>

      <p style="margin-left: 5%;" class="first-wrapper-title">4.1 Principles</p>

      <p style="margin-left: 10% ;">
        This API allows you to do Cashin through Bizao Hub based on the information provided in
        your request.
        This API manages three categories of parameters:
      </p>

      <p style="margin-left: 10% ;">
        <span class="light-span">Headers: </span> contains information letting Bizao to route your traffic by country, operator, channel and type you
        target.
      </p>
      <p style="margin-left: 10% ;"> <span class="light-span">Body-parameters </span> contains detail on your transfer: sender details, beneficiary details,
        amount, currency, the country where the transaction was originated, the
        destination country, destination wallet, etc…</p>
      <p style="margin-left: 10% ;"><span class="light-span">Static-parameters: </span>  this category of parameter cover all parameter that are static
        per merchant for all its remittance transactions (Notification-Fees rules, …). These
        parameters will be provisioned in Bizao Hub (in SignUP step) per partner and will be
        used by Bizao-Hub in the transfer process.</p>


      <p style="margin-left: 5%;" class="first-wrapper-title">4.2 Description</p>

      <p>   Bizao &#60;moneytransfer&#62; is a JSON/Rest Based API

        <br>Below the syntax and descriptive:
      </p>

      <p style="margin-left: 10%;"> <b>Api-name:</b> <span class="light-span">“moneyTransfer”</span></p>
      <p style="margin-left: 10%;"><b>Based URL </b> <span class="light-span"> https://api.bizao.com/ </span> </p>
      <p style="margin-left: 10%;"><b>Method:</b> <span class="light-span"> POST </span></p>


      <p style="margin-left: 10%;" ><b>4.2.1 moneyTransfer API query syntax </b>

      <p> Your query will contain the following Headers:</p>


<br> <br>

      <table>
        <tr>
          <td><b>Header</b> </td>
          <td><b>Description/Content</b> </td>
          <td><b>Usage</b> </td>
        </tr>
        <tr>
          <td>Authorization</td>
          <td>YOUR_ACCESS_TOKEN</td>
          <td>Mandatory</td>
        </tr>
        <tr>
          <td>mno-name</td>
          <td>(string) the name of operator you target
            example : orange, mtn. Bizao will update you
            regarding the new mno set in the hub</td>
          <td>Mandatory</td>
        </tr>
        <tr>
          <td>country-code</td>
          <td>(string)2 characters code of the country you target
            ( use this norme : ISO 3166 alpha 2 country code, url
            to get the all country-code list:
            https://www.iban.com/country-codes
            (for instance:&#60;ci&#62; is the country-code for ivory Coast.</td>
          <td>Mandatory</td>
        </tr>
        <tr>
          <td>channel</td>
          <td>The type of targeted channel using one of keyword:
            &#60;web&#62;:to target the Bizao payment flow <br>
            &#60;type&#62;:to target the Bizao TPE payment flow <br>
            &#60;Ussd&#62;:to target the Bizao USSD payment flow <br>
           </td>
          <td>Mandatory</td>
        </tr>

        <tr>
          <td>type</td>
          <td>The type of the transfer (Wallet, cash or bank) <span class="light-span">bulk </span></td>
          <td>Mandatory</td>
        </tr>
        <tr>
          <td>lang</td>
          <td>the abbreviation in 2 characters of targeted
            language for the request, In ISO 639 (alpha-2) format</td>
          <td>Mandatory</td>
        </tr>
        <tr>
          <td>content-type </td>
          <td>application/json</td>
          <td>Mandatory</td>
        </tr>
      </table>

      <br> <br>

     <p> The body of your query will contain the parameters below into a <b><i>“challenge” object (JSON)</i></b> : </p>

     <table>
      <tr>
        <td><b>Parameter Name</b> </td>
        <td><b>Description</b> </td>
        <td><b>Usage</b> </td>
      </tr>
      <tr>
        <td>currency</td>
        <td>(string) currency identifier as defined in [ISO4217]. Note
          (as described for the amount parameter) that either
          currency and amount or code must be specified. you
          can use this site to know the currency-code by country:
          https://fr.iban.com/currency-codes.html <br>

          <b>Note:</b> for Sandbox testing use <b>“OUV’</b> as currency.

        </td>
        <td>Mandatory</td>
      </tr>
      <tr>
        <td>order_id </td>
        <td>(string) identifies this create payment request. This field
          SHOULD be present. IT MUST BE UNIQUE FOR THE SYSTEM and
          must follow the following format: “MyMerchantNAme_ID”
          where : ID : is a unique number identifier of transaction </td>
        <td>Mandatory 30 char max</td>
      </tr>
      <tr>
        <td>amount</td>
        <td>(integer) amount to be charged. Cents are not accepted</td>
        <td>Mandatory</td>
      </tr>

      <tr>
        <td>reference</td>
        <td>Reference to the Merchant Name</td>
        <td>Mandatory
          30 char max</td>
      </tr>
      <tr>
        <td>sender</td>
        <td>Object &#60;customer&#62;</td>
        <td>Mandatory, it
          is an object</td>
      </tr>

      <tr>
        <td>beneficiary</td>
        <td>Object &#60;customer&#62;</td>
        <td>Mandatory, it
          is an object</td>
      </tr>

      <tr>
        <td>state</td>
        <td>Parameter up to merchant to set within any value he wants
          to keep over all payment transaction processing; this field
          must be in Encodeded-URL (Bizao do not alternate/update
          this value and send it back within payment
          response/notification)
          </td>
        <td>Mandatory</td>
      </tr>
    </table>

      <br><br><br>

      <p> <u> Customer object</u></p>

      <div class="sample-wrapper">
        <pre>
<div class="code" >
 <b> &#60;&#60; customer::Object &#62;&#62; </b>
 _FirstName <span style="font-size: 12px;"> <i>//customer first name</i></span> <br>
 _LastName <span style="font-size: 12px;"> <i>//customer last name</i></span>
 _Msisdn <span style="font-size: 12px;"> <i> //customer msisdn </i></span>
 _Address <span style="font-size: 12px;"> <i>//customer address</i> </span>
              </div>
              </pre>
              <span> <i>Note: replace the signe (_) by <b>sender</b> or <b>beneficiary</b> like this one: senderFirstName</i></span>

        <br>

        <p> <b> <u> transfer query sample:</u> </b></p>
        <p>
          <b>Note :</b>for each new transfer query you have to provide a new value for “order_id”
          Parameter.
        </p>

        <pre>
          <div class="code" >
POST bulk/v1/ HTTP/1.1
Host: api.bizao.com
<b>Authorization:</b> Bearer cb422427-1wo6-3be2-b15b-sff651s7bs4e
<b>country-code:</b> sn
<b>mno-name:</b> free
<b>lang:</b>fr
<b>channel:</b>web
<b>type:</b> wallet


--d '&#123;

      <span style="color:cornflowerblue;font-size: 12px;">//sender details </span>
       <b>"senderFirstName":</b>""A sender name",
       <b>"senderLastName":</b>"A sender last name",
       <b>"senderAddress":</b>"“A sender address",
       <b>"“senderMobileNumber":</b>"“33612345678",<span style="font-size: 12px;"> <i>//The international mobile number without 00 or + </i></span>

       <span style="color:cornflowerblue;font-size: 12px;">//beneficiary details </span>

       <b>"beneficiaryFirstName":</b>""Ibou",
       <b>"beneficiaryLastName":</b>"Fall",
       <b>"beneficiaryAddress":</b>"Rue 10 angle 20, Medina, Dakar",
       <b>"beneficiaryMobileNumber":</b>"221769876543",<span style="font-size: 12px;"> <i>//The international mobile number without 00 or + </i></span>


       <span style="color:cornflowerblue;font-size: 12px;">//other details </span>

       <b>"currency":</b>""XOF",
       <b>"reference":</b>"MerchantName", <span style="font-size: 12px;"> <i>// is the merchant name in capital </i></span>
       <b>"order_id":</b>"MerchA_1234598762"
       <b>"amount":</b>"10",<span style="font-size: 12px;"> <i>///only integer value is accepted </i></span>
       <b>"state":</b>""param1%3Dvalue1%26param2%3Dvalue2", <span style="font-size: 12px;"> <i>// the Merchant correlation data </i></span>
       <b>"reference":</b>"“Merchant-name",

                        </div>
                        </pre>


            <p style="margin-left: 10%;" ><b>4.2.1 MoneyTransfer API response</b></p>

        <p>Bizao <span class="light-span">&#60;moneyTransfer&#62;</span> API will response in Json format:
           </p>
          <p> <b>Success query response sample </b></p>


          <br>

          <pre>
            <div class="code" >
&#123;
"status": "Successful",
"transaction-Id": "",<span style="font-size: 12px;"> <i>//the transaction id from BIZAO system </i></span>
"senderFirstName": "A sender name",
"senderLastName": "A sender last name",
"senderAddress": “A sender address”,
“senderMobileNumber": "336761234567",<span style="font-size: 12px;"> <i>//The international mobile number without 00 or + </i> </span>

<span style="color:cornflowerblue;font-size: 12px;">//beneficiary details</span>

"beneficiaryFirstName": "Ibou",
"beneficiaryLastName": "Fall",
"beneficiaryAddress": “Rue 10 angle 20, Medina, Dakar” ,
"beneficiaryMobileNumber": “221769876543", to be mapped with the MSISDN in the
southbound request. The international mobile number without 00 or +

<span style="color:cornflowerblue;font-size: 12px;">//other details </span>
"fromCountry": "fr,
"toCountry": "sn",
"currency": "XOF",
"order_id": "MerchA_1234598762",
"amount": 10,
"state": "param1%3Dvalue1%26param2%3Dvalue2" <span style="font-size: 12px;"> <i>// the Merchant correlation data </i></span>
“reference” “Merchant-name”
&#125;

                          </div>
                          </pre>
              <p>
                For the getStatus service, we will link the transaction-id with the intransaction parameter.
              </p>



              <p  class="first-wrapper-title">4.3 Notification flow</p>

              <p> Bizao moneytransfer is a synchronous API. </p>
              <br>
              <p>For each transfer, there are two types of notification:</p>

            <p><span class="light-span">B2C-Notif</span>: this category of notification is for the beneficiary. For each successful
           transfer, the mobile operator will send him an SMS with the details of the transfer.</p>

            <p> <span class="light-span">B2B-Notif</span>: this category of notification is for the bulkpayment customer backend. For each batch of transfers, based on the backend configuration (Asynch), Bizao will
           notify the partner backend (using the partner-Callback when necessary) with the
           final status of the payment transaction. </p>

           <br>

           <pre>
             <div>
           Get /moneyTransfer/v1/<b>getStatus</b>/orderId HTTP/1.1
           Host: api.bizao.com
           Authorization: Bearer cb422427-1wo6-3be2-b15b-sff651s7bs4e
             </div>
           </pre>

           <br><br>


           <pre>
           <div class="code" >

             &#123;
             "meta": &#123;
               "type":"transfer-notif",
               "source": "sn-free",
                "channel": "web"
                &#125;,
                &#123;
               “status” : “successful”,
               “amount” : “xxxxxx”,
               “order-id” : “xxxxxx”,//The unique id from the IRT
               “currency”: “xxxxx”,
               “reference” : “xxxx”,
               “country-code”: “xxxxx”,
               “state”: ”xxxxxxxxxxx”,
                “user_msisdn”:”22575338226 “,
                “fromCountry”:”fr “,
                “toCountry”:”sn “,
                "intransactionId": "", <span style="font-size: 12px;"> <i>//the transaction id from bizao system</i></span>
                "extransactionId": "", <span style="font-size: 12px;"> <i>//the transaction id from the operator</i></span>
                "senderFirstName": "A sender name",
                "senderLastName": "A sender last name",
                "senderAddress": “A sender address”,
                “senderMobileNumber": "33761234567",
                "beneficiaryFirstName": "Ibou",
                "beneficiaryLastName": "Fall",
                "beneficiaryAddress": Medina,
                "beneficiaryMobileNumber": “221769876543",
                &#125;
             &#125;

             </div>
             </pre>



  <p class="first-wrapper-title">Bizao transfer API common error code </p>





  <table>
    <tr>
        <th><b>ErrorCode</b> </th>
        <th><b>Exception Message Text  Error Message</b></th>
        <th><b>Description/ Comments. </b></th>
        <th><b>HTTP Response Code </b></th>

    </tr>
    <tr>
        <td>50</td>
        <td>Forbidden access to the API
          </td>
        <td>Access denied by ACL.
          'Unauthorized Access Layer'
          or 'Unauthorized
          applicationId' or
          ‘Unauthorized country’
        </td>
        <td>
          403 Forbidden
        </td>

    </tr>
    <tr>
      <td>1201</td>
      <td>Forbidden access to the API</td>
      <td>Forbidden transaction</td>
      <td>403 Forbidden</td>
    </tr>
    <tr>
      <td>1202</td>
      <td>Forbidden access to the API</td>
      <td>Invalid merchant key</td>
      <td>403 Forbidden</td>
    </tr>
    <tr>
      <td>1203</td>
      <td>Forbidden access to the API</td>
      <td>Unauthorized currency for   this country</td>
      <td>403 Forbidden</td>
    </tr>

    <tr>
      <td>1204</td>
      <td>Forbidden access to the API</td>
      <td>Order Already exists. The
        order_id must be unique in
        the system. Only one Token
        per order_id</td>
      <td>403 Forbidden</td>
    </tr>


    </table>
    <!-- End of fourth wrapper -->


</div>
