<!-- is shown only when selecting Generate OTP -->
<div class="row">
  <div class="col s12 m6 l6 ">
      <p class="section-subtitle ">API</p>
      <hr style="border-color: #E3E8EE; border-style: solid;">
      <ul class="bar">
          <li><a routerLink="#" [ngClass]="{'is-active' : key === 'bulk'}" (click)="changeTobulk()">bulk
                  API</a></li>
          <li><a routerLink="#" [ngClass]="{'is-active' : key === 'GetBalanceApi'}" (click)="changeToGetBalanceApi()">Getbalance API
                  </a></li>
          <li><a routerLink="#" [ngClass]="{'is-active' : key === 'GetStatusApi'}" (click)="changeToGetStatusApi()">GetStatus API
                  </a></li>
      </ul>
  </div>
  <div class="col s12 m6 l6 ">
      <p class="section-subtitle">Description</p>
      <hr style="border-color: #E3E8EE; border-style: solid;">
      <p *ngIf="key === 'bulk' ">This API allows you to do Cashin in different recipients with just oneclickthrough BizaoHub based on the information provided in your request. </p>
      <p *ngIf="key === 'GetBalanceApi' ">This service is very important for Northbound partners. It will help them to know the remaining balance in their virtual wallet merchantAccount (main wallet) in BIZAO system. </p>
      <p *ngIf="key === 'GetStatusApi' "> The bulkpayment customer can check any transactions in a batch via the “getStatus” service.
        “getStatus” API </p>
  </div>
</div>
<hr style="border-color: #E3E8EE; border-style: solid;">

<div *ngIf=" key === 'bulk' ">
<app-bulk-payment-api> </app-bulk-payment-api>
</div>
<div *ngIf=" key === 'GetBalanceApi' ">
  <app-get-balance-bulk></app-get-balance-bulk>
</div>
<div *ngIf=" key === 'GetStatusApi' ">
  <app-get-status-bulk></app-get-status-bulk>
</div>



