<div class="row">
  <div class="col s12 m10 l10">

    <!-- Start of first wrapper -->


    <div class="first-wrapper section scrollspy" id="generate-challenge">

      <br>
      <!-- Start of Principles Section -->
      <p class="first-wrapper-title">Service Providers access to Bizao APIs</p>
      <p>
        Bizao gateway gives you an access to a range of one-time REST API.
        It provides you end-points to process your business traffic with different Telco-operators in
        Africa. <br> <br>

        To use the API, the service provider needs to sign-up and subscribe to Bizao API’s offers. <br> <br>

        So far, the sign-up & subscription process is made off-line by the Bizao team, which will be
on self-service basis for a future version of Bizao API (already on roadmap). <br><br>

After subscription, the Bizao team will give you an  <span class="light-span">ACCESS_TOKEN</span>to use as Header in all
calls you will make to the API:
</p><br>
 <br>
        <div class="sidestepA">
       <b>Sample of ACCESS_TOKEN:</b>
       <br>
       <em>“4qa1bae4-3f9b-346-9t8b-c0e4d4ef”</em>

        </div>

<br>

<p>
  The B2B connection to the Bizao platform is made in SSL1 to secure your traffic
</p>
<p>
  If the EndUser discover your service trought Orange APP MyPlace, The MyPlace will
authenticate end-user with one of below method:
</p>
   <div class="sidestepA">1.<b>One Time Password </b> (Mobile Connect): <br>
    This is a web explicit authentication mode. It is adapted for all HTTP access. It only
needs a mobile to receive a PIN code by SMS.
   </div> <br>
   <div class="sidestepA">2.<b>3G-Redirect URL </b> <br>
    This is a mobile implicit mode. It works only for MNOs customers connected through
the 3G data network. The result of a successful authentication is sent to a callback
URL provided by your app.
   </div>

   <div class="sidestepA">3.<b>. MO SMS with ShortCode:  </b> <br>
    This is an implicit mode. The authentication process starts by sending a SMS thanks to
a short-code linked to a callback URL provided by your app.
   </div>

   <p>
    The description and use of these methods are explained in dedicated sections within this
document.
   </p>

   <p>
    In the authentication journey, the Bizao platform will generate/provide you a pair of keys
as credentials for each Client authenticated:

   </p>

   <div class="sidestepA"><b>- BIZAO_TOKEN: </b>  <br>
    It’s a unique and limited time ID (1 year) to use for critical API calls such as payment
API)
    <br><br>
    <div class="sidestepA"><b>&#8226; Sample of token: </b> <br>

      <em> “B64ZNgw9MaNekCsFRYf9pe6WZFzYnOKQxoyUagq8v2anGGrY4JG+Xx5MO42
        iFpkEYzf|MCO=OCI|tcd=1526548445|ted=1526348545|uSmSX85ScNIIMK5QH
        OK1kSoN”</em>

      </div>
   </div> <br>

   <p>
    <b>Note:</b> These Keys/ID are made unique by MSISDN and must be used instead of user’s
    MSISDN for all API calls you will made on the Bizao platform.
   </p>

   <br> <br>

   <p class="first-wrapper-title"> Your ACCESS_TOKEN</p>

   <p><b> Main credentials</b></p>
  <p>At subscription, the Bizao team will provide you:</p>

   <span style="margin-left: 5%;">- a <b> valid Bearer ACCESS_TOKEN</b> </span>






</div>
</div>
</div>
