<div class="row">
    <div class="col s12 m10 l10">
        <div class="first-wrapper section scrollspy" id="subscription">
            <p class="first-wrapper-title">
                MTN Subscription notification
            </p> <br>
            <p class="first-wrapper-title">
                1. Subscription notification
            </p>
            <p>Two kind of notification are made by the subscription API :</p>

            <p>&#x2022;&nbsp; <b>B2C-Notification:</b></p>
            <p>For each successful subscription, the endUSer will receive an
                SMS with a message ( the wording of this msg to be committed with Bizao in Onboarding
                steps ) to inform him that his subscription is successful.</p>

            <p>&#x2192; The SMS-Notif are sent by SP or</p>
            <p>&#x2192; SMS-Notif can be sent by the MNO(MTN)</p>


            <p>
              The SP to send the SMS MT need to integrate below SMS MT API . To notify the end_user, youfirst need to
Subscribe him succesfully using Subscription API above.
            </p>
          <p>
            <b>Description SMS MT API: </b>
          </p>

          <p>To notify your end-user by SMS you will use:</p>

          <p>
            &#x2022; Bizao-URI: smsmessaging/v1/outbound/tel%3A%2B767/requests <br>
            &#x2022; Bizao-Host: api.bizao.com
          </p>

          <p>
            The query will contain the Headers set to the specified value below :
          </p>


          <table>
            <tr>
            <th>Parameter</th>
            <th>Description</th>
            <th>Usage</th>
            </tr>

            <tr>
              <td>address</td>
              <td>Recipient user ID is a key in the header
                which contains the end user alias to use.
                Authorized values are: <br> Tel:userMSISDN</td>
              <td>Mandatory, unique</td>
            </tr>
            <tr>
              <td>message</td>
              <td>String, must be URL-escaped as per RFC
                1738 and not longer than 160 characters.</td>
              <td>Mandatory</td>
            </tr>
            <tr>
              <td>senderAddress (*)</td>
              <td>String, see Recap of API-SMS parameter for
                values</td>
              <td>Mandatory</td>
            </tr>
            <tr>
              <td>SenderName</td>
              <td>Firendly name displayed on end user device
                ( size should not exceed 11 characters)</td>
              <td>Mandatory</td>
            </tr>
            <tr>
              <td>clientCorrelator</td>
              <td>String, Uniquely identifies this created SMS
                request. This will be useful in communication
                String, failures while sending the request. By
                using the same clientCorrelator when
                retrying the request WSO2.TELCO HUB avoids
                sending the same SMS again.</td>
              <td>Mandatory</td>
            </tr>
          </table>


          <p>SMS Notification query sample:</p>

            <pre>
            <div class="code">
curl --location --request POST
'https://api.bizao.com/smsmessaging/v1/outbound/tel%3A%2B767/requests' \
--header 'authorization: Bearer YOUR_ACCESS_TOKEN ' \
--header 'cache-control: no-cache' \
--header 'content-type: application/json' \
--header 'bizao-alias: USER_MSISDN' \
--header 'country-code: cm' \
--header 'mno-name: mtn' \
--header 'sp-id: 2370110010769' \
--header 'service-Id: YOUR_SERVICE_ID' \
--header 'Cookie: SERVERID=s0; SERVERID=s1' \
--data-raw ' &#123;
"outboundSMSMessageRequest":  &#123;
"address": [
"tel: USER_MSISDN'"
],
"senderAddress": "tel:+2370000",
"outboundSMSTextMessage":  &#123;
"message": " Welcome SMS "
  &#125;,
"clientCorrelator": " MyCorrealtorID",
"senderName": " NameToDisplay "
  &#125;
  &#125;
            </div>
            </pre>



            <p><b>Note :</b>  for each new SMS-Query you have to provide a new “clientCorrelator” value</p>

            <p>SMS Notification succefull response</p>
            <pre>
              <div class="code">
                 &#123;
                  "outboundSMSMessageRequest":  &#123;
                  "address": [
                  "tel:23766913540"
                  ],
                  "senderAddress": "tel:+2370000",
                  "senderName": "8721",
                  "outboundSMSTextMessage":  &#123;
                  "outboundSMSTextMessage":  &#123;
                  "message": "Welcome Message from BIZAO to MTNCM FOR TEST."
                   &#125;
                   &#125;,
                  "clientCorrelator": "MyCorrealtorID-17JAN",
                  "resourceURL": "https://gateway1a.mife.slamobile.com.my:8243/smsmessaging/outbound/tel%3A%2B767/requests/1610907111565SM36601371090/delivery
                  Infos"
                   &#125;
                   &#125;

              </div>
              </pre>


              <p class="first-wrapper-title">Recap of API-SMS parameter value by authorized country</p>

              <p>Below a recap of specific API-SMS parameter value by authorized country</p>

              <table>
                <tr>
                  <th>Country</th>
                  <th>MCO</th>
                  <th>senderAddress</th>
                </tr>
                <tr>
                  <td>Cameroun  </td>
                  <td>MTN</td>
                  <td>tel:+2370000</td>
                </tr>
                <tr>
                  <td>IvoryCoast  </td>
                  <td>MTN</td>
                  <td>Tel :+2250000</td>
                </tr>
              </table>
              <br>

<p><b> o B2B-Notification: </b> The Bizao BackEnd will also notify the SP’s BackEnd via the SP’s
  CallBack URL. See notification sample below: </p>

  <pre>
    <div class="code">
       &#123;
        "meta":  &#123;
        "type": "subscription-notif",
        "source": "mtn"
         &#125;,
        "subscription-id" : "b2b553ca-405f-4765-8113-ab7eff180943",
        "subscription-status" : "Successful",
        "user-id": "2376636xxxx",
        "sp-id":"2370110010769",
        "product-id":"23701220000029439",
        "service-id":"237012000025033",
        "periodicity": "daily",
        "amount-charged": "1",
        "currency":"XOF",
        "Sub-startdate" : "2020-04-02 12:19:59.000",
        "country-code": "cm",
         &#125;
    </div>
    </pre>


            <br>
            <p>Within this subscription-notification you will have the fields : </p>
            <p> &#x2022;&nbsp; <span class="light-span"><b>The meta data will let you know .</b></span>:</p>
            <p>&#x2192;<b><i>The type of notification according to each flow</i></b>: <code class="code">&#x2039;subscription&#x203A;, &#x2039;unsubscription&#x203A;
                or &#x2039;renewal&#x203A;.</code></p>
            <p>&#x2192;&nbsp;The &#x2039;<b><i>MNO</i></b>&#x203A; source of this notification.</p>
            <p>&#x2022;&nbsp; <span class="light-span"> <b>The body with provide the fields</b> </span></p>
            <p>
                &#x2192;&nbsp; <b><i>“subscription-id”</i></b> : <code class="code">
                        the same ID as Bizao sent you on the real time
response for the subscription query “235efa19-a645-4195-8597-4c48a15482df”
                    </code>. <br> <br>

                &#x2192;&nbsp; <b><i>“subscription-status”</i></b> : <code class="code">the final status of the ongoing subscription : <span class="light-span">“Successful”</span>
    or <span class="light-span">“Failure“</span>.</code> <br> <br>

                &#x2192;&nbsp; <b><i>“user-id”</i></b>: <code
                    class="code">the MSISDN of the user who made a subscription.</code> <br> <br>

                &#x2192;&nbsp; <b><i>“product-id”</i></b>: <code
                    class="code">the product ID you target ( sample “22501220000029439”)</code>. <br> <br>

                &#x2192;&nbsp; <b><i>"service-id”</i></b>: <code
                    class="code">your Service ID you got from Bizao ( its unique per SP”)</code>. <br> <br>

                &#x2192;&nbsp; <b><i>"sp-id"</i></b> : <code
                    class="code">the SP-Id provided in the incoming query</code>. <br> <br>

                &#x2192;&nbsp; <b><i>“periodicity”</i></b>: <code class="code">The periodicity of the subscription if it’s daily, weekly or monthly.

    (Sample “86400” for daily).</code> <br> <br>

                &#x2192;&nbsp; <b><i>“amount-charged”</i></b>: <code
                    class="code">the real amount charged for the user ( sample “100”)</code> <br> <br>

                &#x2192;&nbsp; <b><i>“currency”</i></b> : <code class="code">the currency used ( sample : “XOF”).</code>
                <br> <br>

                &#x2192;&nbsp; <b><i>“Sub-startdate”</i></b> : <code
                    class="code">the real subscription date ( sample: “2019-11-28 15:31:07.000”)</code>, <br> <br>

                &#x2192;&nbsp; <b><i>“country-code”</i></b> : <code
                    class="code">the country your target for this subscription ( sample “ci” for Ivory Coast)</code>
                <br> <br>

                &#x2192;&nbsp; <b><i>“state”</i></b> : <code
                    class="code">the correlation data you sent in the subscription query.</code>
            </p>
            <br>
        </div>

        <div class="first-wrapper section scrollspy" id="renewal">
            <p class="first-wrapper-title">
                2. Renewal notification
            </p>
            <p>Idem as for Subscription flow, Bizao manage two type of notification for the renewal
                flow:</p>
                <p>&#x2022;&nbsp; <b>B2C-Notification:</b></p>
            <p>For each successful subscription, the endUSer will receive an
                SMS with a message ( the wording of this msg to be committed with Bizao in Onboarding
                steps ) to inform him that his subscription is successful.</p>
            <p><b>Note :</b></p>
            <p>&#x2192; The SMS-Notif are sent by SP or</p>
            <p>&#x2192; SMS-Notif can be sent by the MNO(MTN)</p>


            <p>The SP to send the SMS MT for renewal need to integrate SMS MT API described in section (6.1
              Subscription notification) . To notify the end_user, youfirst need to Subscribe him succesfully using
              Subscription API above.
              </p>
            <p>&#x2022;&nbsp; <b>B2B-Notification:</b></p>
            <p>The Bizao backend will also notify the SP’s BackEnd via the SP’s
                CallBack URL. <br> See notification sample below :</p>
                <pre>
                <div class="code">
                   &#123;
"meta":
&#123;
"type": "renewal-notif",
"source": "mtn"
&#125;,
"Subscription-id": "41e8fde7-d7e4-4967-b078-d3f17c193030",
"renewal-id": "240000304702003290501020011005",
"renewal-timestamp": "2020-03-29 06:01:23.000",
"renewal-status": "Successful",
"user-id": "2378576xxxx",
"product-id": "23701220000029439",
"service-id":"237012000025033",
"amount-charged": "1",
"currency": "XOF",
"country-code": "cm"
&#125;
                </div>
                </pre>
                <br>
                <p>Within this renewal-notification you will have the fields:</p>
                <p> &#x2022;&nbsp; <span class="light-span"><b>The meta data will let you know .</b></span>:</p>
                <p>&#x2192;<b><i>The type of notification according to each flow</i></b>: <code class="code">&#x2039;subscription&#x203A;, &#x2039;unsubscription&#x203A;
                    or &#x2039;renewal&#x203A;.</code></p>
                <p>&#x2192;&nbsp;The &#x2039;<b><i>MNO</i></b>&#x203A; source of this notification.</p>
                <p>&#x2022;&nbsp; <span class="light-span"> <b>The body with provide the fields</b> </span></p>
                <p>Notification Body with fields below:</p>
                &#x2192;&nbsp; <b><i>"Subscription-id"</i></b>: <code class="code">the same ID as Bizao sent you on the real time response for the subscription
                    query "0d658cdd-caaa-40d2-a082-fe112f81aa71",
                </code> <br><br>

&#x2192;&nbsp; <b><i>"renewal-id"</i></b>: <code class="code">the id of the renewal transaction,</code> <br><br>
&#x2192;&nbsp; <b><i>"renewal-timestamp"</i></b>: <code class="code">Timestamp of the renewal stransaction</code> <br><br>
&#x2192;&nbsp; <b><i>"renewal-status"</i></b>: <code class="code">the final status of this renewal transaction (sample
    :"Completed"),</code> <br><br>
&#x2192;&nbsp; <b><i>"user-id"</i></b>: <code class="code">the MSISDN of the User,</code> <br><br>
&#x2192;&nbsp; <b><i>"product-id"</i></b>: <code class="code">The Product Id of the involved for this renewal,</code> <br><br>
&#x2192;&nbsp; <b><i>"service-id"</i></b>: <code class="code">your Service ID you got from Bizao ( its unique per SP"),</code> <br><br>
&#x2192;&nbsp; <b><i>"amount-charged"</i></b>: <code class="code">The final amount charged</code> <br><br>
&#x2192;&nbsp; <b><i>"currency"</i></b>: <code class="code">The currency</code> <br><br>
&#x2192;&nbsp; <b><i>"country-code"</i></b>: <code class="code">the country code</code>
        </div>
    </div>

    <div class="col hide-on-small-only s12 m2 l2">
        <ul class="section table-of-contents">
            <li>
                <p class="toc-title">On this page</p>
            </li>
            <li><a href="#subscription">Subscription</a></li>
            <li><a href="#renewal">Renewal</a></li>
        </ul>
    </div>
</div>
