import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FetchDataService } from 'src/app/services/fetch-data.service';
declare var jQuery: any;
@Component({
  selector: 'app-mobile-money-get-status-api',
  templateUrl: './mobile-money-get-status-api.component.html',
  styleUrls: ['./mobile-money-get-status-api.component.css']
})


export class MobileMoneyGetStatusAPIComponent implements OnInit, AfterViewInit {
  req_headers:any=null;
  req_body: any = null;
  res_headers:any=null;
  res_body: any = null;
  pulse: boolean = true;
  res_header_active:boolean=false;



  constructor(private fetchDataService: FetchDataService) {

    this.req_headers={
      "authorization": "Bearer 483a05b2-25e8-314f-8db3-dc8081547458",
      "Accept": "application/json",
      "Content-Type": "application/json",
      "country-code": "sn",
      "mno-name" : "orange"

    }

    this.req_body="There is not Request Body "
  }

  sendRequest() {

this.res_headers="There are no headers in the response"

this.res_body=
  {
    "meta": {
      "type": "payment",
      "source": "sn_orange_mm",
      "channel": "web"
  },
  "status": "Successful",
  "amount": "10",
  "order-id": "Merchant_Order_id_4",
  "currency": "XOF",
  "reference": "Your-reference",
  "date": "2020-09-29 09:34:32.0",
  "state": "param1%3Dvalue1%26param2%3Dvalue2",
  "country-code": "sn",
  "intTransaction-id": "1601372072771",
  "extTransaction-id ": "MP200929.0935.A64320"
  }

    /*
    this.fetchDataService.generate_challenge_otp(environment.ACCESS_TOKEN).subscribe(
      response => {

        console.log(response[0])
        if(response[0].doc.response.success.header.length == 0) {
          this.res_headers= "There are no headers in the response";
          this.res_body = response[0].doc.response.success.body;
        }
        else if(response[0].doc.response.success.body.length == 0) {
          this.res_headers= response[0].doc.response.success.header;
          this.res_body = "There is no body in the response";
        }
        else {
          this.res_headers= response[0].doc.response.success.header;
          this.res_body = response[0].doc.response.success.body;
        }
      }
    );
    */
    this.res_header_active = true
  }

  togglePulse() {
    this.pulse = false;
  }

  ngOnInit(): void {
    (function ($) {
      $(document).ready(function () {
        $('.tabs').tabs();
      });

      $(document).ready(function () {
        $('.scrollspy').scrollSpy();
      });
    })(jQuery);



  }

  ngAfterViewInit() {
    /*
    this.fetchDataService.generate_challenge_otp(environment.ACCESS_TOKEN).subscribe(
      response => {
        console.log(response[0])
        this.req_headers=response[0].doc.request.header;
        this.req_body=response[0].doc.request.body;
      }
    );
    */
  }


}


