import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FetchDataService } from 'src/app/services/fetch-data.service';
declare var jQuery: any;
@Component({
  selector: 'app-bulk-payment-api',
  templateUrl: './bulk-payment-api.component.html',
  styleUrls: ['./bulk-payment-api.component.css']
})
export class BulkPaymentApiComponent implements OnInit,AfterViewInit {
  req_headers:any=null;
  req_body: any = null;
  res_headers:any=null;
  res_body: any = null;
  pulse: boolean = true;
  res_header_active:boolean=false;



  constructor(private fetchDataService: FetchDataService) {

    this.req_headers={
      "authorization": "Bearer 483a05b2-25e8-314f-8db3-dc8081547458",
      "Content-Type": "application/json",
      "country-code": "sn",
      "mno-name" : "orange",
      "lang": "fr",
      "channel":"web",
      "type":"bulk"



    }

    this.req_body=
    {
      "currency":"XOF",
      "reference":"bsc-bulk-mt-XOF",
      "batchNumber":"bulk-sn_07-10-2021_01", //Unique "state": "param1%3Dvalue1%26param2%3Dvalue2",
       "data": [
               {
                 "id": "001" ,  // This id will be concatenated with the batchNumber. The result of this concatenation will be order_id of the request to be sent to the MNO.
                  "beneficiaryFirstName": "Abdou",
                 "beneficiaryLastName": "Diop",
                 "beneficiaryAddress": "",
                 "beneficiaryMobileNumber": "221761234567",
                 "amount": 10,
                 "feesApplicable": "YES",
                 "mno": "free"
               },
                {
                 "id": "002",
                 "beneficiaryFirstName": "Logan",
                 "beneficiaryLastName": "Doo",
                 "beneficiaryAddress": "Rue 10 angle 20, Medina, Dakar",
                 "beneficiaryMobileNumber": "2217754566666",
                 "amount": 1,
                 "feesApplicable": "YES",
                  "mno": "orange"
              }
             ]
             }
  }

  sendRequest() {

this.res_headers="There are no headers in the response"

this.res_body=
  {
    "meta": {
      "source": "bizao", "merchant-name": "bizao-bsc@carbon.super",

      "type": "bulk",
      "currency": "XOF",
      "batchNumber": "bulk-sn_07-10-2021_01",
      "reference": "bsc-bulk-mt-XOF",
      "feesType": "FIXED_FEE",
      "lang": "fr",
      "totalAmount": 11.00,
      "totalFees": 2.00,
      "senderFirstName": "Damith",
      "senderLastName": "Sulochana",
      "senderAddress": "Colombo",
      "senderMobileNumber": "2250512345678",
      "fromCountry": "cm",
      "comment": "Bulk Process will take minimum 0.28 minutes"
      ,
      "data":
      [
      {
      "id": "001",
       "orderId": "bulk-sn_07-10-2021_01-001",
       "mno": "free",
       "beneficiaryFirstName": "Salif",
        "beneficiaryLastName": "KA",
        "beneficiaryAddress": "",
        "beneficiaryMobileNumber": "221765151504",
        "toCountry": "sn",
        "feesApplicable": "Yes",
        "amount": 10.00,
        "fees": 1.00,
        "status": "Pending",
        "intTransaction-Id": "1aa27e52-969b-4fd1-8012-da5606b8de71",
        "extTransaction-Id": ""
      },
      {
      "id": "002",
      "orderId": "bulk-sn_07-10-2021_01-002",
      "mno": "orange",
      "beneficiaryFirstName": "Logan",
      "beneficiaryLastName": "Doo",
      "beneficiaryAddress": "Rue 10 angle 20, Medina, Dakar",
      "beneficiaryMobileNumber": "221775782348",
      "toCountry": "sn",
      "feesApplicable": "Yes",
      "amount": 1.00, "fees": 1.00,
      "status": "Pending",
      "intTransaction-Id": "4be22560-389b-4c69-9e45-e6aa7452b7dd",//the transaction id fromBIZAO system
      "extTransaction-Id": ""//the transaction id from the MNO system
     }
        ]
  }

    /*
    this.fetchDataService.generate_challenge_otp(environment.ACCESS_TOKEN).subscribe(
      response => {

        console.log(response[0])
        if(response[0].doc.response.success.header.length == 0) {
          this.res_headers= "There are no headers in the response";
          this.res_body = response[0].doc.response.success.body;
        }
        else if(response[0].doc.response.success.body.length == 0) {
          this.res_headers= response[0].doc.response.success.header;
          this.res_body = "There is no body in the response";
        }
        else {
          this.res_headers= response[0].doc.response.success.header;
          this.res_body = response[0].doc.response.success.body;
        }
      }
    );
    */
    }
    this.res_header_active = true
  }


  togglePulse() {
    this.pulse = false;
  }

  ngOnInit(): void {
    (function ($) {
      $(document).ready(function () {
        $('.tabs').tabs();
      });

      $(document).ready(function () {
        $('.scrollspy').scrollSpy();
      });
    })(jQuery);



  }

  ngAfterViewInit() {
    /*
    this.fetchDataService.generate_challenge_otp(environment.ACCESS_TOKEN).subscribe(
      response => {
        console.log(response[0])
        this.req_headers=response[0].doc.request.header;
        this.req_body=response[0].doc.request.body;
      }
    );
    */
  }


}
