import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FetchDataService } from 'src/app/services/fetch-data.service';
declare var jQuery: any;
@Component({
  selector: 'app-get-balance-api',
  templateUrl: './get-balance-api.component.html',
  styleUrls: ['./get-balance-api.component.css']
})
export class GetBalanceApiComponent implements OnInit,AfterViewInit {
  req_headers:any=null;
  req_body: any = null;
  res_headers:any=null;
  res_body: any = null;
  pulse: boolean = true;
  res_header_active:boolean=false;



  constructor(private fetchDataService: FetchDataService) {

    this.req_headers={
      "Host": "api.bizao.com",
    "Authorization": "Bearer cb422427-1wo6-3be2-b15b-sff651s7bs4e",
    "type": "bulk"
    }

    this.req_body="There is not Request Body "
  }

  sendRequest() {

this.res_headers="There are no headers in the response"

this.res_body=
  {

    "meta": {
      "type": "account-view",
      "source": "bizao"

      }
      ,

      "currency": "XOF",
      "reference": "sunu_test",
      "main_account_number": "sunu_test2001",
      "main_account_balance": 184.00,
      "advance_amount_provided": 1000.00,
      "loan_amount_taken": 0.00,
      "available_balance": 1184.00
      }



    /*
    this.fetchDataService.generate_challenge_otp(environment.ACCESS_TOKEN).subscribe(
      response => {

        console.log(response[0])
        if(response[0].doc.response.success.header.length == 0) {
          this.res_headers= "There are no headers in the response";
          this.res_body = response[0].doc.response.success.body;
        }
        else if(response[0].doc.response.success.body.length == 0) {
          this.res_headers= response[0].doc.response.success.header;
          this.res_body = "There is no body in the response";
        }
        else {
          this.res_headers= response[0].doc.response.success.header;
          this.res_body = response[0].doc.response.success.body;
        }
      }
    );
    */
    this.res_header_active = true
  }

  togglePulse() {
    this.pulse = false;
  }

  ngOnInit(): void {
    (function ($) {
      $(document).ready(function () {
        $('.tabs').tabs();
      });

      $(document).ready(function () {
        $('.scrollspy').scrollSpy();
      });
    })(jQuery);



  }

  ngAfterViewInit() {
    /*
    this.fetchDataService.generate_challenge_otp(environment.ACCESS_TOKEN).subscribe(
      response => {
        console.log(response[0])
        this.req_headers=response[0].doc.request.header;
        this.req_body=response[0].doc.request.body;
      }
    );
    */
  }


}

