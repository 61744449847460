import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FetchDataService } from 'src/app/services/fetch-data.service';
declare var jQuery: any;

@Component({
  selector: 'app-get-status-bulk',
  templateUrl: './get-status-bulk.component.html',
  styleUrls: ['./get-status-bulk.component.css']
})
export class GetStatusBulkComponent implements OnInit,AfterViewInit {
  req_headers:any=null;
  req_body: any = null;
  res_headers:any=null;
  res_body: any = null;
  pulse: boolean = true;
  res_header_active:boolean=false;



  constructor(private fetchDataService: FetchDataService) {

    this.req_headers=
    {
      "Host": "api.bizao.com",
    "Authorization": "Bearer cb422427-1wo6-3be2-b15b-sff651s7bs4e",
     "Content-Type": "application/json",
     "channel": "web",
     "type": "bulk",
     "Cookie": "AlteonMS17=AVGuRvbEYwp+/a0U3c6Ndg$$; SERVERID=s0;SRV=c4929e28-54ed-4c57-bec6-a50f7151ac41"

    }

    this.req_body="There is not Request Body "
  }

  sendRequest() {

this.res_headers="There are no headers in the response"

this.res_body=
  {
    "meta": {
      "source": "bizao",
      "merchant-name": "bizao-bsc@carbon.super",
      "type": "bulk",
      "currency": "XOF",
      "batchNumber": "bulk-sn_07-10-2021_01",
      "reference": "bsc-bulk-mt-XOF",
      "feesType": "FIXED_FEE",
      "lang": "fr",
      "totalAmount": 11.00,
      "totalFees": 2.00,
      "senderFirstName": "Damith",
      "senderLastName": "Sulochana",
      "senderAddress": "Colombo",
      "senderMobileNumber": "2250512345678",
      "fromCountry": "cm"
      }, "data": [
    {
      "id": "001",
      "orderId": "bulk-sn_07-10-2021_01-001",
      "mno": "free",
      "beneficiaryFirstName": "Salif",
      "beneficiaryLastName": "KA",
      "beneficiaryAddress": "",
      "beneficiaryMobileNumber": "221765151504",
      "toCountry": "sn",
      "feesApplicable": "Yes",
      "amount": 10.00,
      "fees": 1.00,
      "status": "Successful",
      "intTransaction-Id": "1aa27e52-969b-4fd1-8012-da5606b8de71",
      "extTransaction-Id": "CI211007.1411.A95748"
      },
    {
      "id": "002",
      "orderId": "bulk-sn_07-10-2021_01-002",
      "mno": "orange",
      "beneficiaryFirstName": "Logan",
      "beneficiaryLastName": "Doo",
      "beneficiaryAddress": "Rue 10 angle 20, Medina, Dakar",
      "beneficiaryMobileNumber": "221775782348",
      "toCountry": "sn",
      "feesApplicable": "Yes",
      "amount": 1.00,
      "fees": 1.00,
      "status": "Failed",
      "failedReason": "Validation failed, please contact support",
      "intTransaction-Id": "4be22560-389b-4c69-9e45-e6aa7452b7dd",
      "extTransaction-Id": "" }
]
  }

    /*
    this.fetchDataService.generate_challenge_otp(environment.ACCESS_TOKEN).subscribe(
      response => {

        console.log(response[0])
        if(response[0].doc.response.success.header.length == 0) {
          this.res_headers= "There are no headers in the response";
          this.res_body = response[0].doc.response.success.body;
        }
        else if(response[0].doc.response.success.body.length == 0) {
          this.res_headers= response[0].doc.response.success.header;
          this.res_body = "There is no body in the response";
        }
        else {
          this.res_headers= response[0].doc.response.success.header;
          this.res_body = response[0].doc.response.success.body;
        }
      }
    );
    */
    this.res_header_active = true
  }

  togglePulse() {
    this.pulse = false;
  }

  ngOnInit(): void {
    (function ($) {
      $(document).ready(function () {
        $('.tabs').tabs();
      });

      $(document).ready(function () {
        $('.scrollspy').scrollSpy();
      });
    })(jQuery);



  }

  ngAfterViewInit() {
    /*
    this.fetchDataService.generate_challenge_otp(environment.ACCESS_TOKEN).subscribe(
      response => {
        console.log(response[0])
        this.req_headers=response[0].doc.request.header;
        this.req_body=response[0].doc.request.body;
      }
    );
    */
  }


}
