import { Component, OnInit } from '@angular/core';
import { FetchDataService } from 'src/app/services/fetch-data.service';

@Component({
  selector: 'app-test-bulk',
  templateUrl: './test-bulk.component.html',
  styleUrls: ['./test-bulk.component.css']
})
export class TestBulkComponent implements OnInit {

  constructor(private fetchDataService : FetchDataService) { }

  //find 5 day before date .
// Source: https://stackoverflow.com/a/1296374 .




key = this.fetchDataService.dcb_key;
changeKeyToBulkQuery() {
  this.key = "bulkquery";
}
changeKeyToGetBalance() {
  this.key = "getBalance";
}









ngOnInit(): void {

  var d = new Date();

  console.log(d.toLocaleDateString())
d.setDate(d.getDate()-5);

console.log(d.toLocaleDateString())


}

}
