<!-- is shown only when selecting Generate OTP -->

<div class="api-testor-wrapper">

  <div class="divider"></div>
  <div class="row req-name">
      <div class="col s12 m2 l2">Method</div>
      <div class="col s12 m2 l2">URL</div>
      <div class="col s12 m8 l8"></div>
  </div>
  <div class="row method-row">
      <div class="col s12 m2 l2 method">POST</div>
      <div class="col s12 m7 l7 input-field white-text">
          <input type="text" value="https: //api.bizao.com/bulk/v1/getStatus" readonly>
      </div>
      <div class="col s12 m3 l3"><button class="btn-floating btn-large" [ngClass]="{'pulse' : pulse === true }"
              type="submit" name="action" (click)="togglePulse();sendRequest();res_headers.click()"><a
                  href="#response_challenge">send</a></button></div>

  </div>



  <div class="divider"></div>
  <div class="row params-row">
      <div class="">
          <div class="row req-name">
              <p>REQUEST :</p>
          </div>
          <div class="row params-row">
              <ul class="tabs ul_api_testor">
                  <li class="tab col s12 m6 l6"><a href="#req_headers">
                          Headers
                      </a></li>
                  <li class="tab col s12 m6 l6"><a href="#req_body">Body</a></li>
              </ul>
              <div id="req_headers">
                  <pre>{{req_headers | json}}</pre>
              </div>
              <div id="req_body">
                  <pre>{{req_body | json}}</pre>
              </div>
          </div>
      </div>
  </div>



  <div class="section scrollspy" id="response_challenge">
      <div class="row">
          <div class="col s12">
              <div class="row params-row">
                  <div class="">
                      <div class="row req-name">
                          <p>RESPONSE :</p>
                      </div>
                      <div class="row params-row">
                          <ul class="tabs ul_api_testor">
                              <li class="tab col s12 m6 l6"><a [ngClass]="{'active' : res_header_active === true}" href="#res_headers" >Headers</a></li>
                               <li class="tab col s12 m6 l6"><a type="button" #responsebody href="#res_body">Body</a>
                              </li>
                          </ul>
                          <div id="res_headers">
                              <pre *ngIf="res_headers != null">{{res_headers | json}}</pre>
                              <span  *ngIf="res_headers == null">Make the request above to see the response
                                  header</span>
                          </div>
                           <div id="res_body">
                              <pre *ngIf="res_body != null">{{res_body | json}}</pre>
                              <span *ngIf="res_body == null">Make the request above to see the response
                                  body</span>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>
