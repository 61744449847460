<!-- is shown only when selecting Generate OTP -->
<div class="row">
  <div class="col s12 m6 l6 ">
      <p class="section-subtitle ">API</p>
      <hr style="border-color: #E3E8EE; border-style: solid;">
      <ul class="bar">
          <li><a routerLink="#" [ngClass]="{'is-active' : key === 'moneyTransfert'}" (click)="changeTomoneyTransfert()">moneyTransfert
                  API</a></li>
          <li><a routerLink="#" [ngClass]="{'is-active' : key === 'GetBalanceApi'}" (click)="changeToGetBalanceApi()">GetBalance API
                  </a></li>
                  <li><a routerLink="#" [ngClass]="{'is-active' : key === 'GetStatuspi'}" (click)="changeToGetStatusApi()">GetStatus API
                    </a></li>
      </ul>
  </div>
  <div class="col s12 m6 l6 ">
      <p class="section-subtitle">Description</p>
      <hr style="border-color: #E3E8EE; border-style: solid;">
      <p *ngIf="key === 'moneyTransfert' ">This API allows you to do Cashin through Bizao Hub based on the information provided in your request. This API manages three categories of parameters: </p>
      <p *ngIf="key === 'GetBalanceApi' "> This service is used by IRT partners to display their balance details in our hub. It is necessary to pass the account name to display the balance. d </p>
      <p *ngIf="key === 'GetStatusApi' "> This operation is used to obtain the status of a transfer.  </p>

  </div>
</div>
<hr style="border-color: #E3E8EE; border-style: solid;">

<div *ngIf=" key === 'moneyTransfert' ">
<app-api-money-transfert> </app-api-money-transfert>
</div>
<div *ngIf=" key === 'GetBalanceApi' ">
  <app-get-balance-api></app-get-balance-api>
</div>
<div *ngIf=" key === 'GetStatusApi' ">
  <app-money-transfer-get-status-api></app-money-transfer-get-status-api>
</div>


