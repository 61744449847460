<div class="row">
    <div class="col s12 m10 l10">
        <div class="first-wrapper">
            <p class="first-wrapper-title section scrollspy" id="principles">Implicite mode (HE)</p>
            <br>


            <p>
              In this mode the SP will got the User MSISDN from the HE flow (Header Enrichment).
              But first the SP needs to activate this flow in Bizao side/platform, by providing:

            </p>
            <p style="margin-left: 5%;">
              1. A dedicated HE-CallBack URL in format below:
              <br>
              - http://>< SP-DNS-Name>< /SP-DNS-Name>/mtn/he/v1 (only HTTP flow is authorized in MNO side
            </p>
            <p>
              2. The IP address of this URL

            </p>

            <p>
              he MNO will make enrichment on this URL only in 3G traffic and set the User-MSISDN in the
Header below:
            </p>

            <p>
              The MNO will make enrichment on this URL only in 3G traffic and set the User-MSISDN in the
Header below:

            </p>

            <p style="margin-left: 5%">

              - < bizao-msisdn > for MTN CI
              <br>
              - < x-up-calling-line-id > for MTN CM
            </p>




            <p class="first-wrapper-title">1. HLE FLOW LOGIC</p>
            <p>
              In case of 3G connection, the SP will redirect each new user to the SP < HE-CallBack >.
                MNO will add a header < bizao-msisdn >(for MTNCI) or < x-up-calling-line-id > (for MTNCM)
                to this URL for each new redirection and set this header with the User-MSISDN, the SP can
                collect/extract it accordingly.
            </p>
<br>

            <p  class="first-wrapper-title">
             2.  HE Diagram flow
            </p>
            <p>
              Below the HE flow diagram including the subscription and notification steps.
            </p>

            <img src="/assets/images/mtn .png"  alt=""
            class="ui fluid image">
        </div>
    </div>
    <div class="col hide-on-small-only s12 m2 l2">
        <ul class="section table-of-contents">
            <li>
                <p class="toc-title">On this page</p>
            </li>
            <li><a href="#principles">Principles</a></li>
        </ul>
    </div>

